import { SearchState } from "@/types/search";

const state: SearchState = {
    searchModalShown: false,
};

const mutations = {
    SHOW_SEARCH(state: SearchState) {
        state.searchModalShown = true;
    },
    HIDE_SEARCH(state: SearchState) {
        state.searchModalShown = false;
    },
};

const getters = {
    searchModalShown: (state: SearchState) => state.searchModalShown,
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
};
