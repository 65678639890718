import { useFeatureFlag } from '@/composables/useFeatureFlag';
import getColorByStatus from '@/utilities/getColorByStatus';
import { AxiosResponse } from 'axios';

const LoggingInterceptor = (response: AxiosResponse) => {
    if (useFeatureFlag('API_INTERCEPTORS')) {
        console.groupCollapsed(
            `%c${response.status}%c ${response.config.method?.toUpperCase()}%c ${response.config.url} `,
            `color: ${getColorByStatus(response.status)}; font-weight: 100;`,
            'color: #555;',
            'color: #666; font-weight: 100;',
        );
        console.groupEnd();
    }

    return response;
};

export default LoggingInterceptor;
