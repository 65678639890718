import { AxiosResponse } from 'axios';

import { api } from '@/services/ApiService';
import { realtimeSalesMapper, salesMapper } from '@/services/mappers/sales.mapper';

import { RealtimeSalesApiResponse, SalesApiResponse } from '@/types/api/sales';

export default {
    async fetch({ userUuid }: { userUuid: string }) {
        const response: AxiosResponse<{ data: SalesApiResponse }> = await api.get(`/api/users/${userUuid}/sales`);

        return salesMapper(response.data.data);
    },
    async fetchRealTimeSales({ userUuid }: { userUuid: string }) {
        const response: AxiosResponse<{ data: RealtimeSalesApiResponse }> = await api.get(
            `/api/users/${userUuid}/sales/realtime`,
        );

        return realtimeSalesMapper(response.data.data);
    },
    async fetchReportsYears({ userUuid }: { userUuid: string }) {
        const response: AxiosResponse<{ data: string[] }> = await api.get(
            `/api/users/${userUuid}/sales/reports/royalties`,
        );

        return response.data;
    },
    async fetchReportsMonths({ userUuid, year }: { userUuid: string; year: number }) {
        const response: AxiosResponse<{ data: any }> = await api.get(
            `/api/users/${userUuid}/sales/reports/royalties/${year}`,
        );

        return response.data;
    },
    async fetchReports({ userUuid, year }: { userUuid: string; year: number }) {
        const response: AxiosResponse<{ data: any }> = await api.get(
            `/api/users/${userUuid}/sales/reports/royalties/${year}`,
        );

        return response.data;
    },
    async fetchReport({ userUuid, date }: { userUuid: string; date: { year: number; month: number } }) {
        const response: AxiosResponse<{ data: any }> = await api.get(
            `/api/users/${userUuid}/sales/reports/royalties/${date.year}/${date.month}`,
        );

        return response.data;
    },
};
