import PagesService from '@/services/PagesService';

import { PagesActions, PagesGetters, PagesMutations, PagesState } from '@/types/store/pages';

export const state: PagesState = {
    pages: [],
};

export const getters: PagesGetters = {
    all(state) {
        return state.pages;
    },
    find: (state) => (uuid: string) => {
        return state.pages.find((page) => page.uuid === uuid);
    },
};

export const actions: PagesActions = {
    async fetch({ commit, rootState }) {
        const response = await PagesService.list({
            userUuid: rootState.user.uuid,
        });

        commit('SET_PAGES', response);

        return;
    },
    async create({ dispatch, rootState }, form) {
        await PagesService.store({ form, userUuid: rootState.user.uuid });

        dispatch('fetch');

        return;
    },
    async delete({ dispatch, rootState }, pageUuid) {
        await PagesService.destroy({ pageUuid, userUuid: rootState.user.uuid });

        dispatch('fetch');

        return;
    },
};

export const mutations: PagesMutations = {
    SET_PAGES(state, pages) {
        state.pages = pages;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
