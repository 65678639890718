import { AxiosResponse } from 'axios';

import { api } from '@/services/ApiService';
import { userMapper } from '@/services/mappers/user.mapper';

import { UserApiResponse } from '@/types/api/user';

export default {
    async fetch(uuid: string) {
        const response: AxiosResponse<{ data: UserApiResponse }> = await api.get(`/api/user/${uuid}`);

        return userMapper(response.data.data);
    },
    async logout() {
        await api.post(`/logout`);
    },
    async introductionSkip({ uuid }: { uuid: string }) {
        await api.post(`/api/users/${uuid}/introduction_flow/skip`);
    },
    async setGuideShown({ uuid, flag }: { uuid: string; flag: string }) {
        const response: AxiosResponse<{ data: UserApiResponse }> = await api.put(
            `/api/users/${uuid}/introduction_flow`,
            { flag },
        );

        return userMapper(response.data.data);
    },
};
