import Form from 'form-backend-validation';

import AccountService from '@/services/AccountService';

import { VuexActions } from '@/types/store';
import { AccountActions, AccountGetters, AccountMutations, AccountState } from '@/types/store/account';

const state: AccountState = {
    id: null,
    basic: {
        name: null,
        email: null,
        business_name: null,
        legal_entity_type: null,
        phone: null,
    },
    creator: {
        address: {
            address_line_1: '',
            address_line_2: null,
            city: '',
            postcode: '',
            country_iso: '',
        },
        bank_details: {
            bank_account_holder_name: '',
            bank_account_number: '',
            bank_account_sort_code: '',
        },
        vat_number: null,
        vinyl_capacity: 0,
    },
    is_seller: false,
};

export const getters: AccountGetters = {
    basic(state: AccountState) {
        return state.basic;
    },
    isSeller(state: AccountState) {
        return state.is_seller;
    },
    creator(state: AccountState) {
        return state.creator;
    },
};

const actions: AccountActions = {
    async fetch({ commit }: VuexActions, uuid: string) {
        const response = await AccountService.fetch(uuid);

        commit('SET_ACCOUNT', response);
    },
    async saveBasicDetails({ commit, state }: VuexActions, form: typeof Form) {
        const response = await AccountService.saveBasicDetails(state.id, form);

        return commit('SET_BASIC_DETAILS', response);
    },
    async updatePassword({ state }: VuexActions, form: typeof Form) {
        form.name = state.basic.name;
        form.email = state.basic.email;

        await AccountService.updatePassword(state.id, form);
    },
    async saveCreatorDetails({ state }: VuexActions, form: typeof Form) {
        return await AccountService.saveCreatorDetails(state.id, form);
    },
    async updateCreatorDetails({ state }: VuexActions, form: typeof Form) {
        await AccountService.updateCreatorDetails(state.id, form);
    },
};

const mutations: AccountMutations = {
    SET_ACCOUNT(state: AccountState, data: AccountState) {
        state.id = data.id;
        state.basic = { ...data.basic };
        state.creator = { ...data.creator };
        state.is_seller = data.is_seller;
    },
    SET_BASIC_DETAILS(state: AccountState, data: AccountState) {
        state.basic = { ...data.basic };
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
