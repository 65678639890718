import { storeSessionStorageKey } from '@/consts/store';
import { Country, getCountry } from 'countries-and-timezones';

export const getLocaleFromLocalStorage = () => {
    return JSON.parse(localStorage.getItem(storeSessionStorageKey) as string);
};

export const getTimezoneForLocale = (countryCode: string): string => {
    const country: Country | null = getCountry(countryCode);
    return country?.timezones.length ? country.timezones[0] : 'UTC';
};
