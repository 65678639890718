/* global Laravel */
import axios from 'axios';

import { AttachEtagToRequest, GetEtagFromResponse } from '@/services/interceptors/Etag.interceptor';
import LoggingInterceptor from '@/services/interceptors/Logging.interceptor';

const Laravel = window.Laravel ?? {};

export const api = (window.axios = axios.create({
    headers: {
        'X-CSRF-TOKEN': Laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'X-CART-ID': localStorage.getItem('cartId'),
    },
}));

const EtagKeys = {
    '/api/shipping_countries': 'configEtag',
};

if (process.env.NODE_ENV !== 'test') {
    api.interceptors.request.use(
        (config) => {
            const con = AttachEtagToRequest(config, EtagKeys);
            return Promise.resolve(con);
        },
        (error) => {
            return Promise.reject(error);
        },
    );

    api.interceptors.response.use(
        async (response) => {
            GetEtagFromResponse(response, EtagKeys);

            return LoggingInterceptor(response);
        },
        (error) => {
            return Promise.reject(error);
        },
    );
}
