import { createI18n } from 'vue-i18n';

import { getLocaleFromLocalStorage } from '@/utilities/locales';

import Locale from './vue-i18n-locales.generated';

const storage = getLocaleFromLocalStorage();

const locale = storage?.locales?.locale || 'en_GB';

export const config = {
    legacy: true,
    locale,
    //allowComposition: true,
    messages: Locale,
    fallbackLocale: 'en',
    silentFallbackWarn: true,
    silentTranslationWarn: true,
};

const i18n = createI18n(config);

export default i18n;
