import ReleaseGroupsService from '@/services/ReleaseGroupsService';

import {
    ReleaseGroupsActions,
    ReleaseGroupsGetters,
    ReleaseGroupsMutations,
    ReleaseGroupsState,
} from '@/types/store/release_groups';

export const state: ReleaseGroupsState = {
    releaseGroups: [],
    releaseGroup: null,
    releases: [],
};

export const getters: ReleaseGroupsGetters = {
    getReleaseGroups: (state) => {
        return state.releaseGroups;
    },
    getReleaseGroup: (state) => {
        return state.releaseGroup;
    },
    getReleases: (state) => {
        return state.releases;
    },
};

export const actions: ReleaseGroupsActions = {
    async fetchAll({ commit, rootState }, { releaseUuid }) {
        const response = await ReleaseGroupsService.fetchAll({
            userId: rootState.user.uuid,
            releaseId: releaseUuid,
        });

        commit('SET_RELEASE_GROUPS', response);
    },
    async fetch({ commit }, { pageUuid, releaseGroupUuid }) {
        const response = await ReleaseGroupsService.fetch({
            pageUuid: pageUuid,
            releaseGroupUuid: releaseGroupUuid,
        });

        commit('SET_RELEASE_GROUP', response);
    },
    async fetchReleases({ commit }, { pageUuid, releaseGroupUuid }) {
        const response = await ReleaseGroupsService.fetchReleases({
            pageUuid: pageUuid,
            releaseGroupUuid: releaseGroupUuid,
        });

        commit('SET_RELEASES', response);
    },
};

export const mutations: ReleaseGroupsMutations = {
    SET_RELEASE_GROUPS(state, releaseGroups) {
        state.releaseGroups = releaseGroups;
    },
    SET_RELEASE_GROUP(state, releaseGroup) {
        state.releaseGroup = releaseGroup;
    },
    SET_RELEASES(state, releases) {
        state.releases = releases;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
