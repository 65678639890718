import { PublicReleaseTypesApiResponse, ReleasesApiResponse, ReleaseTypesApiResponse } from '@/types/api/releases';
import { PublicReleaseTypes, ReleasesListItem, ReleaseTypes } from '@/types/releases';

export const releasesMapper: (response: ReleasesApiResponse[]) => ReleasesListItem[] = (response) => {
    return response.map((release) => ({
        uuid: release.uuid,
        release_date: release.release_date,
        preorder_date: release.preorder_date,
        ean: release.ean,
        title: release.title,
        primary_artist: release.primary_artist,
        explicit: release.explicit,
        label: release.label,
        description: release.description,
        release_type: {
            id: release.release_type.id,
            product_type: {
                description: release.release_type.product_type.description,
                image_url: release.release_type.product_type.image_url,
                image_icon_url: release.release_type.product_type.image_icon_url,
            },
            format: release.release_type.format,
        },
        release_group_uuid: release.release_group_uuid,
        territories: release.territories,
        territories_exclude: release.territories_exclude,
        notes: release.notes,
        cover: release.cover,
        track_count: release.track_count,
        quantity_limit: release.quantity_limit,
        sold_amount: release.sold_amount,
        submitted_at: release.submitted_at,
        in_process_at: release.in_process_at,
        retired_at: release.retired_at,
        canRetire: !release.in_process_at && !release.retired_at,
        canDelete: !release.in_process_at && !!release.retired_at,
        canClone: !release.in_process_at,
        readyToSubmit:
            !release.submitted_at && release.metadata_status === 'Complete' && release.artwork_status === 'Complete',
        // && release.metadata_tracks === 'Complete' - This value doesn't seem to exist (??)
    }));
};

export const releaseTypesMapper: (response: ReleaseTypesApiResponse[]) => ReleaseTypes[] = (response) => {
    return response.map((type) => ({
        id: type.id,
        description: type.description,
        format: type.format,
        enabled: type.enabled,
    }));
};

export const publicReleaseTypesMapper: (response: PublicReleaseTypesApiResponse[]) => PublicReleaseTypes[] = (
    response,
) => {
    return response.map((type) => ({
        id: type.id,
        description: type.description,
        artwork_format: type.artwork_format,
        format: type.format,
    }));
};
