import { AxiosRequestConfig, AxiosResponse } from 'axios';

export const AttachEtagToRequest = (config: AxiosRequestConfig, etagMapping: { [url: string]: string }) => {
    const etagKey = etagMapping[config.url as string];

    if (etagKey && config.headers['Cache-Control'] !== 'no-cache') {
        const etag = localStorage.getItem(etagKey);

        if (etag) {
            config.headers['If-None-Match'] = etag;
        }
    }

    return config;
};

export const GetEtagFromResponse = (response: AxiosResponse, etagMapping: { [url: string]: string }) => {
    const etagKey = etagMapping[response.config.url as string];

    if (etagKey && response.headers['etag']) {
        localStorage.setItem(etagKey, response.headers['etag']);
    }

    return response;
};
