import { AxiosResponse } from 'axios';

import { api } from '@/services/ApiService';
import { cartMapper } from '@/services/mappers/cart.mapper';
import { notify } from '@/functions/useNotification';

import { CartApiResponse } from '@/types/api/cart';
import { CartItem } from '@/types/cart';

export default {
    async fetch() {
        const response: AxiosResponse<{ data: CartApiResponse; message: string }> = await api.get(`/es/cart`);

        if (response.data.message) {
            notify(response.data.message, 'WARNING');
        }

        return cartMapper(response.data.data);
    },
    async store({ item }: { item: CartItem }) {
        const response: AxiosResponse<{ data: '' }> = await api.post(`/es/cart`, {
            ...item,
        });

        return response;
    },
    async remove({ itemUuid, itemType }: { itemUuid: string; itemType: string }) {
        const response: AxiosResponse<{ data: '' }> = await api.delete(`/es/cart/${itemUuid}?type=${itemType}`);

        return response;
    },
    async update({ item, quantity }: { item: CartItem; quantity: number }) {
        const response: AxiosResponse<{ data: '' }> = await api.put(`/es/cart/${item.uuid}`, {
            quantity,
            type: item.type,
        });

        return response;
    },
    async checkout() {
        return api.post('/es/checkout');
    },
    async bulkCheckout(data: { release_uuid: string; quantity: number; country_iso: string }) {
        return api.post('/es/bulk-checkout', data);
    },
};
