import ReleasesService from '@/services/ReleasesService';

import { ReleasesActions, ReleasesGetters, ReleasesMutations, ReleasesState } from '@/types/store/releases';

export const state: ReleasesState = {
    releases: [],
    types: [],
    publicTypes: [],
    cloningInProgress: [],
};

export const getters: ReleasesGetters = {
    releases: (state) => state.releases,
    types: (state) => state.types,
    publicTypes: (state) => state.publicTypes,
    isCloningInProgress: (state) => {
        return (uuid) => {
            return state.cloningInProgress.includes(uuid);
        };
    },
};

export const actions: ReleasesActions = {
    async clone({ rootState }, form) {
        return await ReleasesService.clone({ userUuid: rootState.user.uuid, form });
    },
    async create({ rootState, dispatch }, form) {
        await ReleasesService.create({ userUuid: rootState.user.uuid, form });

        dispatch('fetch');
    },
    async delete({ rootState, dispatch }, releaseUuid) {
        await ReleasesService.destroy({
            userUuid: rootState.user.uuid,
            releaseUuid,
        });

        dispatch('fetch');
    },
    async fetch({ commit, rootState }) {
        const response = await ReleasesService.list(rootState.user.uuid);

        commit('SET_RELEASES', response);
    },
    async fetchPublicTypes({ commit }) {
        const response = await ReleasesService.listPublicTypes();

        commit('SET_PUBLIC_TYPES', response);
    },
    async fetchTypes({ commit, rootState }) {
        const response = await ReleasesService.listTypes(rootState.user.uuid);

        commit('SET_TYPES', response);
    },
    async retire({ rootState, dispatch }, releaseUuid) {
        await ReleasesService.retire({
            userUuid: rootState.user.uuid,
            releaseUuid,
        });

        await dispatch('fetch');
    },
    updateCloningStatus: function ({ commit, state }, { uuid, status }) {
        commit('SET_CLONING_STATUS', {'uuid': uuid, 'status':status} );
    },
    isCloningInProgress: (state) => (uuid) => {
        const release = state.cloningInProgress.find((item) => item === uuid);
        return release ? true : false;
    },
};

export const mutations: ReleasesMutations = {
    SET_RELEASES(state, releases) {
        state.releases = releases;
    },
    SET_TYPES(state, types) {
        state.types = types;
    },
    SET_PUBLIC_TYPES(state, types) {
        state.publicTypes = types;
    },
    SET_CLONING_STATUS(state, data) {
        if (data.status) {
            if (!state.cloningInProgress.includes(data.uuid)) {
                state.cloningInProgress.push(data.uuid);
            }
        } else {
            const index = state.cloningInProgress.indexOf(data.uuid);
            if (index > -1) {
                state.cloningInProgress.splice(index, 1);
            }
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
