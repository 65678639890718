import { FILE_FORMAT } from '@/consts/media';

import { CurrentTrack, FileFormats, IPlaylist, Track } from '@/types/media';

export class Playlist implements IPlaylist {
    private tracks: Track[] = [];
    private currentIndex: number | null = null;

    private transformToCurrentTrack(track: Track | undefined): CurrentTrack {
        return {
            artist: track?.primary_artist || '',
            src: (track?.master && track.master[FILE_FORMAT as FileFormats]) || track?.preview || '',
            title: track?.title || '',
            uuid: track?.uuid || '',
        };
    }

    build(tracks: Track[]): void {
        this.tracks = tracks;
        this.currentIndex = tracks.length ? 0 : null;
    }

    find(uuid: string): CurrentTrack | undefined {
        const track = this.tracks.find((t) => t.uuid === uuid);

        if (track) {
            this.currentIndex = this.tracks.indexOf(track);
        }

        return this.transformToCurrentTrack(track);
    }

    skip(direction: 'prev' | 'next'): { track: CurrentTrack; ended: boolean } | null {
        if (this.currentIndex === null) return null;

        let ended = false;

        if (direction === 'prev' && this.currentIndex > 0) {
            this.currentIndex--;
        } else if (direction === 'next' && this.currentIndex < this.tracks.length - 1) {
            this.currentIndex++;
        } else if (direction === 'next' && this.currentIndex === this.tracks.length - 1) {
            this.currentIndex = 0;
            ended = true;
        }

        return {
            track: this.transformToCurrentTrack(this.tracks[this.currentIndex]),
            ended,
        };
    }
}
