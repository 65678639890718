import { CurrentTrack } from '@/types/media';

export const DEFAULT_CURRENT_TRACK: CurrentTrack = {
    src: '',
    title: '',
    artist: '',
    uuid: '',
};

export const PLAYER_FOOTER = 'footer';

export const PLAYER_RELEASES = 'releases';

export const FILE_FORMAT = 'mp3';

export const AUDIO_FILE_TYPE = `audio/${FILE_FORMAT}`;
