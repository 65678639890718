import { DEFAULT_CURRENT_TRACK } from '@/consts/media';

import { Playlist } from '@/classes/Playlist';

import { IPlaylist, PlayerType, Track } from '@/types/media';
import { VuexActions } from '@/types/store';
import { MediaActions, MediaGetters, MediaMutations, MediaState } from '@/types/store/media';

export const state: MediaState = {
    audioContext: null,
    sourceNode: null,
    isPlaying: false,
    isAudioLoaded: false,
    player: null,
    playlist: null,
    current: DEFAULT_CURRENT_TRACK,
    albumHasEnded: false,
};

export const getters: MediaGetters = {
    isPlaying(state: MediaState) {
        return state.isPlaying;
    },
    isAudioLoaded(state: MediaState) {
        return state.isAudioLoaded;
    },
    isCurrent:
        (state: MediaState) =>
        ({ uuid }: { uuid: string }) => {
            return state.current.uuid === uuid;
        },
    current(state: MediaState) {
        return state.current;
    },
    player(state: MediaState) {
        return state.player;
    },
    playlist(state: MediaState) {
        return state.playlist;
    },
    audioContext(state: MediaState) {
        return state.audioContext;
    },
    sourceNode(state: MediaState) {
        return state.sourceNode;
    },
    albumHasEnded(state: MediaState) {
        return state.albumHasEnded;
    },
};

export const actions: MediaActions = {
    buildPlaylist({ commit }: VuexActions, tracks: Track[]) {
        const playlist = new Playlist();
        playlist.build(tracks);
        commit('buildPlaylist', { playlist });
    },
    setTrack({ commit }: VuexActions, { uuid, shouldPlay = true }: { uuid: string; shouldPlay?: boolean }) {
        commit('setTrackData', { uuid });
        commit('setPlaying', shouldPlay);
    },
    setPlayer({ commit }: VuexActions, { player }: { player: string }) {
        commit('setPlayer', { player });
    },
    togglePlayback({ state, dispatch }: VuexActions) {
        state.isPlaying ? dispatch('stopPlayback') : dispatch('startPlayback');
    },
    startPlayback({ commit }: VuexActions) {
        commit('setPlaying', true);
    },
    stopPlayback({ commit }: VuexActions) {
        commit('setPlaying', false);
    },
    skipBack({ commit }: VuexActions) {
        commit('skipTrack', 'prev');
    },
    skipForward({ commit }: VuexActions) {
        commit('skipTrack', 'next');
    },
    setAudioLoaded({ commit }: VuexActions, isAudioLoaded: boolean) {
        commit('setAudioLoaded', isAudioLoaded);
    },
    setAudioContext({ commit }: VuexActions, audioContext: AudioContext | null) {
        commit('setAudioContext', { audioContext });
    },
    setSourceNode({ commit }: VuexActions, sourceNode: MediaElementAudioSourceNode | null) {
        commit('setSourceNode', { sourceNode });
    },
    async initializeAudio({ commit, state }: VuexActions, audioElement: HTMLAudioElement) {
        if (!state.audioContext) {
            const AudioContext = window.AudioContext;
            const audioContext = new AudioContext();
            commit('setAudioContext', { audioContext });
        }

        if (!state.sourceNode && state.audioContext) {
            const sourceNode = state.audioContext?.createMediaElementSource(audioElement);
            sourceNode?.connect(state.audioContext.destination);
            commit('setSourceNode', { sourceNode });
        }
    },
    refreshCurrentTrack({ commit, state }: VuexActions) {
        commit('setTrackData', { uuid: state.current.uuid });
    },
    async resumeAudioContext({ state }: VuexActions) {
        if (state.audioContext?.state === 'suspended') {
            await state.audioContext.resume();
        }
    },
};

export const mutations: MediaMutations = {
    buildPlaylist(state: MediaState, { playlist }: { playlist: IPlaylist }) {
        state.playlist = playlist;
    },
    setTrackData(state: MediaState, { uuid }: { uuid: string }) {
        state.current = state.playlist?.find(uuid) || DEFAULT_CURRENT_TRACK;
    },
    setPlaying(state: MediaState, isPlaying: boolean) {
        state.isPlaying = isPlaying;
    },
    setAudioLoaded(state: MediaState, isAudioLoaded: boolean) {
        state.isAudioLoaded = isAudioLoaded;
    },
    setPlayer(state: MediaState, { player }: { player: PlayerType }) {
        state.player = player;
    },
    setAudioContext(state: MediaState, { audioContext }: { audioContext: AudioContext | null }) {
        state.audioContext = audioContext;
    },
    setSourceNode(state: MediaState, { sourceNode }: { sourceNode: MediaElementAudioSourceNode | null }) {
        state.sourceNode = sourceNode;
    },
    skipTrack(state: MediaState, direction: 'prev' | 'next') {
        const newTrack = state.playlist?.skip(direction);

        if (newTrack?.ended) {
            state.albumHasEnded = true;
            state.isPlaying = false;
        }

        state.current = newTrack?.track || DEFAULT_CURRENT_TRACK;
    },
    albumHasEnded(state: MediaState, albumHasEnded: boolean) {
        state.albumHasEnded = albumHasEnded;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
