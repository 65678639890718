import PageService from '@/services/PageService';

import { PageActions, PageGetters, PageMutations, PageState } from '@/types/store/page';

export const state: PageState = {
    page: {
        uuid: '',
        name: '',
        url: '',
        content_title: '',
        description: '',
        bio: '',
        header_image: '',
        page_image: '',
        status: 'Offline',
        release_count: 0,
        twitter_handle: '',
        youtube_playlist_id: '',
        youtube_channel_url: '',
        youtube_channel_id: '',
        created_at: '',
        updated_at: '',
        days_until_removed: 0,
        videos: '',
        instagram_posts: '',
        instagram_connected: false,
        instagram_username: '',
    },
};

export const actions: PageActions = {
    async fetch({ commit, rootState }, uuid) {
        const response = await PageService.show({
            userUuid: rootState.user.uuid,
            pageUuid: uuid,
        });

        commit('SET_PAGE', response);
    },
    async update({ state, commit, rootState }, form) {
        const response = await PageService.update({
            userUuid: rootState.user.uuid,
            pageUuid: state.page.uuid,
            form,
        });

        commit('SET_PAGE', response);
    },
    /* async fetchInstagramUrl({ commit }, url) {
        return axios
            .get(`/api/instagram`, {
                params: { url },
            })
            .then((response) => {
                commit('SET_INSTAGRAM_URL', response.data.thumbnail_url);
            })
            .catch(() => {
                commit('SET_INSTAGRAM_URL', '');
            });
    },
    clearInstagramUrl({ commit }) {
        commit('SET_INSTAGRAM_URL', '');
    }, */
};

export const mutations: PageMutations = {
    SET_PAGE(state, page) {
        state.page = page;
    },
    SET_PAGE_IMAGE(state, { url }: { url: string }) {
        state.page.page_image = url;
    },
    SET_HEADER_IMAGE(state, { url }: { url: string }) {
        state.page.header_image = url;
    },
    /* SET_INSTAGRAM_URL(state, url) {
        state.instagramUrl = url;
    }, */
};

export const getters: PageGetters = {
    page: (state: PageState) => state.page,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
