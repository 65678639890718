import moment from 'moment';

import { CartApiResponse } from '@/types/api/cart';
import { Cart } from '@/types/cart';

export const cartMapper: (response: CartApiResponse) => Cart = (response) => {
    const furthestDispatchDate = (response.items || []).reduce((latestDate, item) => {
        const itemDispatchDate = moment(item.meta.dispatch_date);
        return itemDispatchDate.isAfter(latestDate) ? itemDispatchDate : latestDate;
    }, moment(0));

    return {
        items: response.items.map((item) => ({
            type: item.type,
            uuid: item.uuid,
            quantity: item.quantity,
            priceIncVat: item.amountIncVat, // e.g. 2999
            priceIncVatFormatted: item.priceIncVat || '', // e.g. '£29.99'
            meta: {
                title: item.meta.title,
                artist: item.meta.artist,
                description: item.meta.description,
                artwork: item.meta.artwork,
                release_icon: item.meta.release_icon,
                explicit: item.meta.explicit,
            },
        })),
        totalQuantity: (response.items || []).reduce((acc, item) => acc + item.quantity, 0),
        totalIncVat: response.amountIncVat, // e.g. 2999
        totalIncVatFormatted: response.priceIncVat, // e.g. '£29.99'
        hasLimitedEdition: (response.items || []).some((item) => item.isLimitedEdition),
        hasPreorders: response.hasPreorders,
        shippingMinPrice: response.shippingMinPrice,
        vatText: response.vatText,
        dispatchDate: furthestDispatchDate.isValid() ? furthestDispatchDate.format('D MMMM') : null,
    };
};
