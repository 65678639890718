import ReleaseGroupsService from '@/services/ReleaseGroupsService';

import { ArtistReleaseGroupsApiResponse } from '@/types/api/release_groups';
import { ArtistReleaseGroup } from '@/types/release_groups';
import { VuexActions } from '@/types/store';
import { ArtistActions, ArtistGetters, ArtistMutations, ArtistState } from '@/types/store/artist';

export const state: ArtistState = {
    page: {},
    albums: [],
    singlesEps: [],
};

export const getters: ArtistGetters = {};

export const actions: ArtistActions = {
    async fetchReleaseGroups({ commit }: VuexActions, { page }: { page: string }) {
        const response: { singleEps: ArtistReleaseGroup[]; albums: ArtistReleaseGroup[] } =
            await ReleaseGroupsService.fetchArtistReleaseGroups({ page });

        commit('SET_SINGLE_EPS', response.singleEps);
        commit('SET_ALBUMS', response.albums);
    },
};

export const mutations: ArtistMutations = {
    SET_SINGLE_EPS(state: ArtistState, singleEps: ArtistReleaseGroupsApiResponse[]) {
        state.singlesEps = singleEps;
    },
    SET_ALBUMS(state: ArtistState, albums: ArtistReleaseGroupsApiResponse[]) {
        state.albums = albums;
    },
    SET_PAGE(state: ArtistState, page: any) {
        state.page = page;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
