import GenresService from '@/services/GenresService';

import { Genre, GenreListItem, GenreReleaseGroups, GenresListResponse } from '@/types/genres';
import { VuexActions } from '@/types/store';
import { GenresActions, GenresGetters, GenresMutations, GenresState } from '@/types/store/genres';

const state: GenresState = {
    items: [],
    current: {},
    releaseGroups: [],
    mainGenresList: [],
    subGenresList: [],
};

const getters: GenresGetters = {
    all(state: GenresState) {
        return state.items.sort((a: Genre, b: Genre) => a.name.localeCompare(b.name));
    },
    getMainGenresList(state: GenresState): GenreListItem[] {
        return state.mainGenresList;
    },
    getSubGenresList(state: GenresState): GenreListItem[] {
        return state.subGenresList;
    },
    getReleaseGroups(state: GenresState) {
        return state.releaseGroups;
    },
};

const actions: GenresActions = {
    async fetch({ commit }: VuexActions) {
        const response = await GenresService.fetch();

        commit('SET_GENRES', response);
    },
    async fetchGenresList({ commit }: VuexActions) {
        const response = await GenresService.fetchGenresList();

        commit('SET_GENRES_LIST', response);
    },
    async fetchReleaseGroups({ commit }: VuexActions, genre) {
        const response = await GenresService.fetchReleaseGroups(genre);

        commit('SET_RELEASE_GROUPS', response);
    },
};

const mutations: GenresMutations = {
    SET_GENRES(state: GenresState, genres: Genre[]) {
        state.items = genres;
    },
    SET_GENRES_LIST(state: GenresState, genres: GenresListResponse) {
        state.mainGenresList = genres.main_genres;
        state.subGenresList = genres.sub_genres;
    },
    SET_GENRE(state: GenresState, genre: Genre) {
        state.current = genre;
    },
    SET_RELEASE_GROUPS(state: GenresState, releaseGroups: GenreReleaseGroups[]) {
        state.releaseGroups = [...releaseGroups];
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
