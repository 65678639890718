import { AxiosResponse } from 'axios';
import Form from 'form-backend-validation';

import { api } from '@/services/ApiService';
import { publicReleaseTypesMapper, releasesMapper, releaseTypesMapper } from '@/services/mappers/releases.mapper';

import { PublicReleaseTypesApiResponse, ReleasesApiResponse, ReleaseTypesApiResponse } from '@/types/api/releases';

export default {
    async list(userId: string) {
        const response: AxiosResponse<{ data: ReleasesApiResponse[] }> = await api.get(`/api/users/${userId}/releases`);

        return releasesMapper(response.data.data);
    },
    async listTypes(userId: string) {
        const response: AxiosResponse<{ data: ReleaseTypesApiResponse[] }> = await api.get(
            `/api/users/${userId}/release_types`,
        );

        return releaseTypesMapper(response.data.data);
    },
    async listPublicTypes() {
        const response: AxiosResponse<{ data: PublicReleaseTypesApiResponse[] }> = await api.get(`/api/release_types`);

        return publicReleaseTypesMapper(response.data.data);
    },
    async create({ userUuid, form }: { userUuid: string; form: InstanceType<typeof Form> }) {
        // This endpoint returns the new release but it is not used. Should return empty data in response.
        const response: AxiosResponse<{ data: '' }> = await form.post(`/api/users/${userUuid}/releases`);

        return response.data.data;
    },
    async clone({ userUuid, form }: { userUuid: string; form: InstanceType<typeof Form> }) {
        const response: AxiosResponse = await form.post(`/api/users/${userUuid}/releases/${form.clone_uuid}/clone`);

        return response.data;
    },
    async retire({ userUuid, releaseUuid }: { userUuid: string; releaseUuid: string }) {
        // This endpoint returns the retired release but it is not used. Should return empty data in response.
        const response: AxiosResponse<{ data: '' }> = await api.post(
            `/api/users/${userUuid}/releases/${releaseUuid}/retire`,
        );

        return response.data.data;
    },
    async destroy({ userUuid, releaseUuid }: { userUuid: string; releaseUuid: string }) {
        const response: AxiosResponse = await api.delete(`/api/users/${userUuid}/releases/${releaseUuid}`);

        return response.data;
    },
};
