import { AxiosResponse } from 'axios';

import { api } from '@/services/ApiService';
import {
    artistReleaseGroupsMapper,
    releaseGroupsForLabelMapper,
    releaseGroupsMapper,
    releaseGroupsReleasesMapper,
} from '@/services/mappers/release_groups';

import {
    AlbumReleaseGroupsApiResponse,
    ArtistReleaseGroupsApiResponse,
    ReleaseGroupsForLabelApiResponse,
    ReleaseGroupsReleasesApiResponse,
} from '@/types/api/release_groups';

export default {
    async fetchAll({ userId, releaseId }: { userId: string; releaseId: string }) {
        const response: AxiosResponse<{ data: ReleaseGroupsForLabelApiResponse[] }> = await api.get(
            `/api/users/${userId}/releases/${releaseId}/release_groups`,
        );

        return releaseGroupsForLabelMapper(response.data.data);
    },
    // Used by ReleaseGroup.vue
    async fetch({ pageUuid, releaseGroupUuid }: { pageUuid: string; releaseGroupUuid: string }) {
        const response: AxiosResponse<{ data: AlbumReleaseGroupsApiResponse }> = await api.get(
            `/api/pages/${pageUuid}/release_groups/${releaseGroupUuid}`,
        );

        return releaseGroupsMapper(response.data.data);
    },
    // Used by ReleaseGroup.vue
    async fetchReleases({ pageUuid, releaseGroupUuid }: { pageUuid: string; releaseGroupUuid: string }) {
        const response: AxiosResponse<{ data: ReleaseGroupsReleasesApiResponse[] }> = await api.get(
            `/api/pages/${pageUuid}/release_groups/${releaseGroupUuid}/releases?preview=true`,
        );

        return releaseGroupsReleasesMapper(response.data.data);
    },
    // Used only by the artist store module
    async fetchArtistReleaseGroups({ page }: { page: string }) {
        const response: AxiosResponse<{ data: ArtistReleaseGroupsApiResponse[] }> = await api.get(
            `/api/release_groups?media=Vinyl,CD&page=${page}`,
        );

        return artistReleaseGroupsMapper(response.data.data);
    },
};
