export const PERMANENT_FEATURES: { [key: string]: string } = {
    API_INTERCEPTORS: 'API_INTERCEPTORS',
    GRID_AXIS_GUIDES: 'GRID_AXIS_GUIDES',
};

export const TEMPORARY_FEATURES: { [key: string]: string } = {};

export const FEATURES = {
    ...PERMANENT_FEATURES,
    ...TEMPORARY_FEATURES,
};

const LOCAL_STORAGE_KEY = 'es-feature-flags';

export const useFeatureFlag = (flag: string) => {
    const isProduction: boolean = import.meta.env.VITE_APP_ENV === 'production';
    const envVar: string | boolean | undefined = import.meta.env[`VITE_FEATURE_${flag}`];

    const featureFlags: { [key: string]: boolean } = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');

    if (isProduction) {
        return false;
    }

    // If the envVar is defined and is 'true', return true
    // If the flag is not defined in envVar, check FEATURE_TOGGLES
    // Default to false if not found
    return envVar === 'true' || (envVar === undefined && featureFlags[flag] === true);
};

export const logFeatureToggles = () => {
    const isProduction: boolean = import.meta.env.VITE_APP_ENV === 'production';
    const availableFeatures: string[] = Object.keys(FEATURES);
    const activeFeatures: string[] = [];

    if (isProduction) {
        return;
    }

    Object.keys(FEATURES).forEach((feature) => {
        const isActive = useFeatureFlag(feature);

        if (isActive) {
            activeFeatures.push(feature);
        }
    });

    if (availableFeatures.length > 0) {
        console.log(
            '%celasticStage',
            'color: #ffa55a; font-weight: bold; font-size: 20px; font-family: BlinkMacSystemFont;',
        );
        console.log(
            "%cToggle feature flags by running %ctoggleFeatureFlag('FEATURE_NAME')%c in the console.",
            'font-weight: bold;',
            'color: #ddd; background: #333; border-radius: 5px; padding: 4px 8px;',
            'margin-bottom: 20px; font-weight: bold;',
        );
        console.log('AVAILABLE FEATURE FLAGS: ', availableFeatures);
        console.log('ACTIVE FEATURE FLAGS: ', activeFeatures);
    }
};

window.toggleFeatureFlag = (flag: string) => {
    const featureFlags: { [key: string]: boolean } = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');

    featureFlags[flag] = !featureFlags[flag];

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(featureFlags));

    window.location.reload();
};
