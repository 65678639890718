import { AxiosResponse } from 'axios';

import { api } from '@/services/ApiService';

import { GenresListResponse } from '@/types/genres';

export default {
    async fetch() {
        const response: AxiosResponse<{ data: any }> = await api.get(`/api/active_genres`);

        return response.data.data;
    },
    async fetchGenresList(): Promise<GenresListResponse> {
        const response: AxiosResponse<{ data: any }> = await api.get(`/api/genres_list`);

        return response.data;
    },
    async fetchReleases(genre: string) {
        const response: AxiosResponse<{ data: any }> = await api.get(`/api/genres/${genre}/releases`);

        return response.data.data;
    },
    async fetchReleaseGroups(genre: string) {
        const response: AxiosResponse<{ data: any }> = await api.get(`/api/genres/${genre}/release_groups`);

        return response.data.data;
    },
};
