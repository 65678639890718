export default {
    de: {
        ' to avoid problems with your release.': '',
        '(YYYY-MM-DD)': '(JJJJ-MM-TT)',
        '* Required Field': '* Erforderliches Feld',
        '{attribute} is not valid': '{Attribut} nicht gültig',
        'A fresh confirmation link  has been sent to your email address.':
            'A neuer Bestätigunslink wurde an deine Emailadresse geschickt.',
        'A/B': '',
        Accepted: 'Akzeptiert',
        'Access Token': 'Access Token',
        Account: 'Konto',
        'Account Name': 'Kontoname',
        Add: 'Hinzufügen',
        'Add a Pre Order date': 'Füge ein Vorbestellungsdatum hinzu',
        'Add country': 'Land hinzufügen',
        'Add one country at a time. If you need to add another country press <code>Add</code> again afterwards.':
            'Füge ein Land auf einmal hinzu. Falls Sie ein weiteres Land hinzufügen müssen, drücken Sie <code>Hinzufügen</code> anschließend nochmal.',
        'Add to all Songs': 'Füge bei allen Songs hinzu',
        Address: 'Adresse',
        'Address Line 1': '',
        'Address Line 2': '',
        'Address Name': '',
        Admin: '',
        'After the app is configured, enter the currently showing authentication code in the box below and click "Activate 2FA"':
            '',
        'After the upload click on every song to check and complete the song metadata. Clicking on the same or another song (closing the current song edit panel), but also clicking &quot;Done&quot; will store any changed data. Please read the info':
            '',
        Album: 'Album',
        'All of the latest releases.': '',
        'All rights reserved': 'Alle Rechte vorbehalten',
        'All rights reserved.': 'All rights reserved.',
        'All you need to do is register.': 'All you need to do is register.',
        'All your favourite artists. Made just for you.': '',
        'An error occurred. Please check your PDF format.':
            'Ein Fehler ist aufgetreten. Bitte prüfen Sie Ihr PDF format.',
        Apple: 'Apple',
        Approved: 'Genehmigt',
        Archived: 'Archived',
        'Are you sure you want to run this action? This will remove the Release from any Page it is associated with':
            'Are you sure you want to run this action? This will remove the Release from any Page it is associated with',
        'Artist Name': 'Künstlername',
        'Artist role': 'Künstlerrolle',
        Artwork: 'Artwork',
        'Artwork controls - ': '',
        'Audio File': '',
        'Auth Token': 'Auth Token',
        Authenticated: '',
        Authenticating: '',
        'Authentication Valid Until': 'Authentifizierung gültig bis',
        Author: 'Autor',
        'Authorise Instagram': 'Autorisiere Instagram',
        Available: 'Verfügbar',
        'Available on ': 'Verfügbar am',
        'Back to orders': '',
        'Bank Account Added': 'Bankkonto hinzugefügt',
        'Bank Account Deleted': 'Bankkonto gelöscht',
        'Be the first to experience the new generation of vinyl records.': '',
        'Before proceeding, please check your email for a confirmation link.':
            'Before proceeding, please check your email for a confirmation link.',
        'Billing same as Delivery Address': '',
        Bio: 'Bio',
        'Business Address': 'Geschäftsadresse',
        'Business Type': 'Geschäftsart',
        Cancel: 'Abbrechen',
        Caption: 'Titel',
        'Catalogue Symbol': 'Catalogue Symbol',
        CD: 'CD',
        'Change E-Mail': 'Wechsle E-Mail',
        'Change Instagram Account': '',
        'Changing the currency will not change the values below. You will need to manually amend them to get the correct price':
            'Changing the currency will not change the values below. You will need to manually amend them to get the correct price',
        Checkout: 'Kasse',
        'Choose a Playlist from your channel': '',
        'Choose a new password': 'Choose a new password',
        'Choose a panel type': '',
        City: '',
        'Click here to find out more about Google 2FA.': '',
        'Click here to request another': 'Click here to request another',
        'Click here to return <a href="{url}">home</a>': 'Click here to return <a href="{url}">home</a>',
        'Click on a track to listen to it.': '',
        'Clone process started...': 'Kloneprozess begonnen...',
        'Cloned From Release': 'Gekloned von Release',
        'Cloning Release...': 'Klone Release...',
        'Close Account': 'Konto schließen',
        'Cloud storage': 'Cloudspeicher',
        'Code is not valid': 'Code ist ungültig',
        Colour: 'Farbe',
        'Colour Palette': 'Farbpalette',
        'Coming pre-orders': 'Kommende Vorbestellungen',
        'Coming releases': 'Kommende Veröffentlichungen',
        Company: 'Firma',
        'Conditions of Use & Privacy': 'Unsere AGB & Datenschutzerklärung',
        'Confirm E-Mail': 'Bestätige E-Mail',
        'Confirm E-Mail Change': 'Bestätige E-Mail Änderung',
        'Confirm Your E-Mail Address': 'Bestätige Deine E-Mail Addresse',
        'Content Title': 'Content Title',
        'Continue Shopping': '',
        'Contribute to this page': '',
        Contributors: 'Mitwirkende',
        'Copy share link': 'Kopiere Share-Link',
        Country: 'Land',
        'Create Event': 'Event erstellen',
        'Create, sell and buy custom vinyl records and music merchandise. We dispatch your products within 72 hour.':
            '',
        Created: 'Created',
        'Created At': '',
        'Creating Release...': 'Erstelle Release...',
        Currency: 'Währung',
        'Currency Symbol': 'Währungssymbol',
        'Customer Amount ex. Tax': 'Customer Amount ex. Tax',
        'Customer Tax Amount': 'Customer Tax Amount',
        'Dark Theme': 'Dark Theme',
        Date: 'Datum',
        'Date can only be {num_days} days into the future.': 'Datum kann nur {num_days} Tage in der Zukunft sein.',
        'Date cannot be in the past.': 'Datum kann nicht in der Vergangenheit sein.',
        'Dealer Price (PPD)': '',
        'Decimal Digits': 'Dezimalpunkte',
        'Default Thumbnail': 'Default Thumbnail',
        'Deliver to': 'Liefern an',
        Description: 'Beschreibung',
        'Discount PPD': 'Discount PPD',
        'Discount RRP': 'Discount RRP',
        'Don\'t put the song version - e.g. (Remix by XYY) or (Instrumental Version) - in the Title. The song version has to go into the Subtitle without any brackets - e.g. "()" or "[]". NEVER use the word "Original" for a song version, as this will be rejected by stores. Any title without a version is automatically considered the "Original Version". The words "Part" and "Volume" should be abbreviated with "Pt." and "Vol." followed by a space. Upper case is only allowed for acronyms and random casing is not allowed - e.g (myTRAck).':
            '',
        Draft: 'Draft',
        'Drag the artwork model to interact with it.': '',
        'Drag the panels to reorder them.': '',
        'Drop your files here, or ': '',
        'Duration limit exceeded': '',
        'E-Mail Change Notification': 'E-Mail Change Notification',
        'ELASTICSTAGE CODE': 'ELASTICSTAGE CODE',
        'ELASTICSTAGE EMERGENCY': 'ELASTICSTAGE EMERGENCY',
        ERROR: '',
        "Each Release (except Promo Releases) must be assigned to a Page (your promotion platform and store). Create the required Page first or you can't assign it. Complete the Release Metadata before adding Songs. Don't list more than one person or group in the Primary Artist field. The Royalty is what you get paid per sold item. If you don't have a record label use your name or the artist's name. More info about Releases":
            '',
        'Edit Event': 'Event ändern',
        'Edit Panel': '',
        'Email address': 'E-Mailadresse',
        Ep: 'Ep',
        Etag: 'Etag',
        'Event Name': 'Eventname',
        Events: 'Events',
        'Everything you need to sell your music': '',
        'Explicit Lyrics': '',
        'Explicit Warning': '',
        Facebook: 'Facebook',
        Failed: 'Fehlgeschlagen',
        Featured: '',
        'Files must be WAV or FLAC and meet the following requirements: 2 Channels (Stereo), Sample Rate: 44.1, 48, 88.2 or 96 kHz, Bit Depth: 16 or 24 bit, minimum length: 31 sec.':
            'Files müssen WAV oder FLAC sein und die folgenden Anforderungen erfüllen: 2 Kanäle (Stereo), Sample Rate: 44.1, 48, 88.2 or 96 kHz, Bit Depth: 16 or 24 bit, Mindestlänge: 31s.',
        'Find your next release by Genre.': '',
        'First Name': 'Vorname',
        'For Artists': '',
        'For music lovers': '',
        'Forgot your password?': 'Dein Passwort vergessen?',
        Format: 'Format',
        'Fulfillment Controller': 'Fulfillment Controller',
        GRid: '',
        Genres: 'Genres',
        'Get notified when we launch': '',
        'Go to your Record Label': 'Go to your Record Label',
        'Google 2-factor authentication (2FA) is an optional tool for sellers to make their accounts more secure. More info in the Help section.':
            '',
        'Google 2FA is now active!': 'Google 2FA ist nun aktiviert!',
        'Google location data missing.': 'Google-Ortsdaten fehlen.',
        Height: 'Height',
        Hello: 'Hello',
        'Hello {Name},': 'Hallo {Name},',
        'Hello {name},': 'Hello {name},',
        'Hello!': 'Hello!',
        Help: 'Hilfe',
        'High Resolution Thumbnail': 'High Resolution Thumbnail',
        Home: 'Home',
        'I agree to elasticStage\\\'s <a href="{url}" target="_blank">Terms & Conditions</a>.':
            'I agree to elasticStage\\\'s <a href="{url}" target="_blank">Terms & Conditions</a>.',
        "I'm looking for...": '',
        ID: 'ID',
        ISRC: '',
        'ISRC already in use and conflicting with ': 'ISRC bereits verwendet und ist im Konflikt mit ',
        'If you are happy with your artwork, approve it.': '',
        'If you did not change the E-Mail address, no further action is required.':
            'Falls Sie die Emailadresse nicht gewechselt haben, ist nichts weiter zu tun.',
        'If you did not create a new account, no further action is required.':
            'Falls Sie kein neues Konto eröffnet haben, ist nichts weiter zu tun.',
        'If you did not make this change, please contact us at <a href="mailto:{email_address}">{email_address}</a>.':
            'If you did not make this change, please contact us at <a href="mailto:{email_address}">{email_address}</a>.',
        'If you did not receive the email': 'If you did not receive the email',
        'If you did not request a password reset, no further action is required.':
            'Falls Sie keinen Passwortwechsel beantragt haben, ist nichts weiter zu tun.',
        'If you did not request this, please get in touch immediately':
            'If you did not request this, please get in touch immediately',
        'Illegal selection.': 'Illegale Auswahl.',
        'In process': 'In Bearbeitung',
        Incomplete: 'Unvollständig',
        'Inner Sleeve': '',
        'Inner Sleeve A/B': '',
        'Inner Sleeve C/D': '',
        Instagram: 'Instagram',
        'Instagram Account': 'Instagram Account',
        'Instagram Authentication complete': 'Instagram Authentication complete',
        'Instagram post could not be found.': '',
        'Internal notes, e.g. "French Release"': 'Internal notes, e.g. "French Release"',
        'Invalid Abartn': 'Invalid Abartn',
        'Invalid Account Number': 'Invalid Account Number',
        'Invalid IBAN': 'Invalid IBAN',
        'Invalid SortCode': 'Invalid SortCode',
        'Invalid time.': 'Ungültige Zeit.',
        'Is Fake': 'Is Fake',
        'Is Seller': 'Is Seller',
        'Is Suppressed': 'Is Suppressed',
        'Is Video': 'Is Video',
        'It looks like you have reached a page that does not exist.':
            'It looks like you have reached a page that does not exist.',
        'Last Name': 'Nachname',
        'Last Refreshed': 'Last Refreshed',
        Link: '',
        Live: 'Live',
        'Loading Account Data...': '',
        'Log In': 'Anmelden',
        'Log in': 'Anmelden',
        'Log in to your account': 'Logge dich in dein Konto ein',
        Logout: 'Logout',
        'Luxe PPD': 'Luxe PPD',
        'Luxe RRP': 'Luxe RRP',
        'Main Colour': 'Main Colour',
        'Main Genre': 'Hauptgenre',
        'Make default': '',
        'Manage your account settings.': '',
        'Master File Name': 'Master File Name',
        'Max Resolution Thumbnail': 'Max Resolution Thumbnail',
        'Maximum seconds': 'Maximum seconds',
        'Maximum seconds per side': 'Maximum seconds per side',
        'Maximum tracks': 'Maximum tracks',
        'Maximum tracks per side': 'Maximum tracks per side',
        "Maybe an album isn\\'t released, or the page isn\\'t ready yet.":
            "Maybe an album isn\\'t released, or the page isn\\'t ready yet.",
        'Media/Online Event': 'Media/Online Event',
        Medium: 'Medium',
        'Medium Thumbnail': 'Medium Thumbnail',
        'Meta Data': 'Metadaten',
        'Minimum seconds': 'Minimum seconds',
        'Minimum seconds per side': 'Minimum seconds per side',
        'Minimum tracks': 'Minimum tracks',
        'Minimum tracks per side': 'Minimum tracks per side',
        'More info <a href="{url}">here</a>.': 'Mehr Info <a href="{url}">hier</a>.',
        'My Account': '',
        'My Orders': 'My Orders',
        'My Pages': '',
        'My Record Label': '',
        'My Releases': '',
        Name: 'Name',
        'New Event': 'Neuer Event',
        'New Page': '',
        'New YouTube Channel': '',
        'New artist': '',
        'No results could be found': '',
        'No results found': 'Keine Treffer gefunden',
        'None, Create new': '',
        'Not Authenticated': '',
        'Not available': '',
        'Nova User': 'Nova User',
        'Only submit your release once you have <span class=&quot;font-bold&quot;>very carefully</span> checked all the data!':
            'Senden Sie den Release nur ab, nachdem Sie alle Daten <span class=&quot;font-bold&quot;>sehr sorgfältig</span> geprüft haben!',
        or: 'oder',
        'Or search': '',
        Order: '',
        'Order Submitted': '',
        'Order details': '',
        'Order placed': '',
        'Order Proof': 'Proof bestellen',
        'Orders placed': '',
        'Original Release Date': 'Ursprüngliches Releasedatum',
        'Original Release Date must be in the past.': 'Ursprüngliches Releasedatum muss in der Vergangenheit sein.',
        'Our wide catalogue of products are made to order, your record made by us and delivered right to your door, usually within 72 hours.':
            '',
        Override: 'Override',
        PRIVATE: 'PRIVAT',
        Page: 'Seite',
        'Page Name': 'Seitenname',
        'Page Name not part of Description.': 'Seitenname nicht Teil der Beschreibung.',
        'Page Name not part of Meta Title.': 'Page Name not part of Meta Title.',
        'Page Url': 'Page Url',
        Pages: 'Seiten',
        Paid: 'Paid',
        Password: 'Passwort',
        'Password Confirmation': '',
        Pending: 'In Bearbeitung',
        'Phone Number': '',
        'Phone Number Not Reachable:': 'Telefonnummer nicht erreichbar:',
        'Play time': '',
        'Playlist Id': 'Playlist Id',
        'Please alter your search': '',
        'Please carefully check the address bar, or go back and try again':
            'Please carefully check the address bar, or go back and try again',
        "Please confirm it\\'s still you": 'Bitte bestätige, dass es noch du bist',
        'Please enter your Authentication Code:': 'Bitte gib deinen Authentifizierungscode ein:',
        'Please select the country where your credit card is registered:':
            'Bitte wähle das Land, wo deine Kreditkarte registriert ist:',
        'Please select your language': '',
        'Please select your preferred shipping country:': '',
        'Please select your shipping country': '',
        'Please wait for the other uploads to complete and be processed': '',
        'Please wait until the processing is finished': '',
        'Postage &amp; Packing': '',
        Posts: 'Posts',
        'Pre-Order Date must be 2-25 weeks into future.': 'Vorbestellungsdatum muss 2-25 Wochen in der Zukunft sein.',
        'Pre-Order Date must be earlier than Release Date.':
            'Vorbestellungsdatum muss vor dem Veröffentlichungsdatum sein.',
        'Premium PPD': 'Premium PPD',
        'Premium RRP': 'Premium RRP',
        Price: '',
        'Price Level': 'Price Level',
        'Price Sets': 'Price Sets',
        'Pricing unavailable in your region': '',
        Primary: '',
        'Primary Artist': 'Hauptkünstler',
        'Product Type': 'Product Type',
        Promo: 'Promo',
        Promos: 'Promos',
        Published: 'Published',
        Qty: '',
        'Qty:': '',
        'RRPs include VAT': 'RRPs include VAT',
        'Ready to submit': 'Bereit zum Absenden',
        'Reauthenticate me': 'Reauthenticate me',
        'Record Label': 'Plattenlabel',
        'Refund sold tickets first and delete categories.':
            'Rückvergüte verkaufte Tickets zuerst und lösche Kategorien.',
        Regards: 'Regards',
        Register: 'Registrierung',
        Rejected: 'Abgelehnt',
        Release: 'Release',
        'Release Date must be 2-25 weeks into future.': 'Veröffentlichungsdatum muss 2-25 Wochen in der Zukunft sein.',
        'Release Group': 'Releasegruppe',
        'Release Type': 'Releasetype',
        'Release Types': 'Releasetypen',
        'Release as soon as possible': 'So bald wie möglich veröffentlichen',
        Releases: 'Releases',
        Remaining: '',
        'Remember me': 'Angemeldet bleiben',
        Remove: 'Entfernen',
        'Remove ticket categories first.': 'Entfernen Sie zuerst Ticketkategorien.',
        'Removed soon': 'Bald entfernt',
        Replace: 'Austauschen',
        'Required Field': 'Notwendiges Feld',
        'Reset Password': 'Passwort Reset',
        'Reset my password': 'Reset my password',
        'Reset password': 'Reset password',
        'Retail (ex. tax)': '',
        'Retire or Delete': '',
        Retired: 'Retired',
        Role: 'Rolle',
        'Royalty Payable': 'Royalty Payable',
        'Royalty Percent': 'Royalty Percent',
        'SOLD OUT!': 'AUSVERKAUFT!',
        Sales: 'Verkäufe',
        Save: '',
        'Save Changes': '',
        Search: '',
        'Search Time Zone': 'Search Time Zone',
        'Search for your YouTube Playlist': '',
        'Search results': '',
        'Search...': 'Suche...',
        Select: 'Wähle',
        'Select a country': '',
        'Select a page': '',
        'Select a release group': 'Wähle eine Releasegruppe',
        'Seller Account Update': 'Seller Account Update',
        'Seller Amount': 'Seller Amount',
        'Send reset link': 'Send reset link',
        'Set as Default?': '',
        'Shipping Address': '',
        'Shipping Countries': 'Shipping Countries',
        'Shipping Methods': 'Shipping Methods',
        'Short info about the event (4,000 chars max)': 'Kurze Veranstaltungsinfo (max. 4.000 Zeichen)',
        Sides: 'Sides',
        'Sign up': '',
        Single: 'Single',
        Size: 'Size',
        Slug: 'Slug',
        'Social Media': 'Social Media',
        'Sold Out': 'Ausverkauft',
        'Someone has invited you to register for an elasticStage account.':
            'Someone has invited you to register for an elasticStage account.',
        'Song limit exceeded': '',
        'Songs, albums, artists or genres...': 'Songs, Alben, Künstler oder Genres...',
        Speed: 'Speed',
        Spotify: 'Spotify',
        'Standard PPD': 'Standard PPD',
        'Standard RRP': 'Standard RRP',
        'Standard Resolution Thumbnail': 'Standard Resolution Thumbnail',
        Stats: 'Stats',
        Status: 'Status',
        'Status Changed': 'Status Changed',
        'Stay up to date with the latest news about elasticStage.': '',
        'Sub Genre': 'Subgenre',
        'Submit Release': 'Release absenden',
        Subtitle: 'Untertitel',
        'Success!': 'Erfolg!',
        'Supporting Act': 'Support-Act',
        Tax: '',
        'Tax Office': 'Finanzamt',
        Territory: 'Gebiet',
        Text: '',
        'Thank you for using our application!': 'Thank you for using our application!',
        'That password is incorrect, please try again': 'That password is incorrect, please try again',
        'The Main Admin Panel.': '',
        'The PDF colour space is incorrect. Should be RGB and not CMYK.':
            'Der PDF Colourspace ist inkorrekt. Soll RGB und nicht CMYK sein.',
        'The PDF page count is incorrect.': 'Die PDF-Seitenanzahl ist inkorrekt.',
        'The PDF size (width/height) is incorrect.': 'Die PDF-Größe (Breite/Höhe) ist inkorrekt.',
        'The artwork must be a PDF using our provided templates and follow our specs or it will be rejected. More info <a href="{url}">here</a>.':
            'Die Artwork muss ein PDF sein, das unsere zu Verfügung gestellten Templates verwendet und Spezifikationen folgt, sonst wird es abgelehnt. Mehr Info <a href="{url}">hier</a>.',
        'The format for Additional Artists is invalid.': 'Das Format für Weitere Künstler ist ungültig.',
        'The format for ISRC is invalid.': 'Das Format für ISRC ist ungültig.',
        'The password must be at least 8 characters long and have at least one upper case, one lower case, and one numeric character.':
            'Das Passwort muss mindestens 8 Zeichen lang sein, mindestens einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl haben.',
        'The percentage of PPD a business will earn when selling e.g. 20 = 24%':
            'The percentage of PPD a business will earn when selling e.g. 20 = 24%',
        'The public description': 'The public description',
        'The supplied EAN-13 is not a valid barcode.': 'Der bereitgestellte EAN-13 ist kein gültiger Barcode.',
        'The supplied GRid is not valid.': 'Die bereitgestellte GRid ist nicht gültig.',
        'There are currently no shipping methods available for your address.': '',
        'This page has no Main Colour attribute set. Use the "UpdateMainColourAttribute" Action to set one':
            'This page has no Main Colour attribute set. Use the "UpdateMainColourAttribute" Action to set one',
        'This release has no Main Colour attribute set. Use the "UpdateMainColourAttribute" Action to set one':
            'This release has no Main Colour attribute set. Use the "UpdateMainColourAttribute" Action to set one',
        Thumbnail: 'Thumbnail',
        'Thumbnail Preview:': '',
        Thumbnails: 'Thumbnails',
        'Ticket System': 'Kartensystem',
        'Ticket URL': 'Ticket-URL',
        Tickets: 'Karten',
        Time: 'Zeit',
        'Time Free': '',
        'Time Zone': 'Zeitzone',
        'Time Zone missing.': 'Zeitzone fehlt.',
        Title: 'Titel',
        'Total Play Time': '',
        'Total Tracks': '',
        'Total price': '',
        'Total price (ex. tax)': '',
        'Total price (inc. tax)': '',
        'Total tax': '',
        'Track Listing': '',
        Tracks: '',
        Twitter: 'Twitter',
        'Type the name of a city': 'Tippen Sie den Namen einer Stadt',
        'Type the venue name or address': 'Tippen Sie den Veranstaltungsort oder Adresse',
        URL: 'URL',
        Unpaid: 'Unpaid',
        'Update Event': 'Event ändern',
        Updated: 'Updated',
        'Upload tracks': '',
        Uploading: '',
        'Uploading song': '',
        'Uploading...': '',
        Uuid: 'Uuid',
        'VAT Rate': 'VAT Rate',
        Venue: 'Veranstaltungsort',
        'Verification Code sent to:': 'Bestätigungscode gesendet an:',
        Video: '',
        'Video Id': 'Video Id',
        Videos: 'Videos',
        'View & manage all of your pages & releases.': '',
        'View & manage all of your pages.': '',
        'View & manage all of your releases.': '',
        'View all of your orders in one place.': '',
        'View of all your orders in one place.': '',
        Vinyl: 'Vinyl',
        'Vinyl Cover': '',
        'Waiting for confirmation of new email': '',
        'Waiting for confirmation of new email: ': 'Warten auf Bestätigung der neuen Emailadresse: ',
        Warning: 'Warnung',
        'We currently do not ship to this country': 'We currently do not ship to this country',
        'We have some exciting news for you.': 'We have some exciting news for you.',
        'We would like to let you know that either your seller account status has been updated or other data about your <a href=\\"{url}\\">Account</a> has changed.':
            'We would like to let you know that either your seller account status has been updated or other data about your <a href=\\"{url}\\">Account</a> has changed.',
        'Welcome to your home': 'Welcome to your home',
        'Whoops!': 'Whoops!',
        Width: 'Width',
        'With no minimum order, no warehouse, no piles of albums to sell, we are the future of vinyl sales. The best quality is made for your customers when they need it.':
            '',
        Yes: 'Ja',
        'You account has been deleted!': 'Ihr Konto wurde gelöscht!',
        'You already have a page with this name.': 'Sie haben bereits eine Seite mit diesem Namen.',
        'You are Home': 'You are Home',
        'You are not allowed to access this page': 'You are not allowed to access this page',
        'You are not allowed to choose that Release Group. Please choose one with a similar Artist and Release Name':
            'You are not allowed to choose that Release Group. Please choose one with a similar Artist and Release Name',
        'You are receiving this email because someone added the following bank account:':
            'Sie erhalten dieses E-Mail, da jemand das folgende Bankkonto hinzugefügt hat.',
        'You are receiving this email because someone created a new account with your E-Mail address. Please click the Confirm E-Mail button within 24 hours to activate your account and delete the message afterwards. It will work only once.':
            'Sie erhalten dieses E-Mail, da jemand ein neues Konto mit dieser Emailadresse angelegt hat. Bitte klicken Sie auf den Bestätige E-Mail Button, um das Konto zu aktivieren und löschen Sie diese Nachricht anschließend. Der Button funktioniert nur einmal.',
        'You are receiving this email because someone deleted the following bank account:':
            'Sie erhalten dieses E-Mail, da jemand das folgende Bankkonto gelöscht hat.',
        'You are receiving this email because someone wants to change your E-Mail address.':
            'Sie erhalten dieses E-Mail, da jemand Ihre Emailadresse wechseln möchte.',
        'You are receiving this email because we received a password reset request for your account.':
            'Sie erhalten dieses E-Mail, da wir eine Anfrage für einen Passwort-Reset für Ihr Konto bekommen haben.',
        'You are still in the "Edit" mode with your Metadata or Songs. Please click "Update" or "Done" to exit the "Edit" mode before submitting your release.':
            'Sie sind noch auf "Ändern" in den Metadaten oder Songs. Klicken Sie bitte auf "Aktualisieren" oder "Fertig", um den Ändernmodus zu verlassen, bevor Sie den Release absenden.',
        'You can close this window': 'You can close this window',
        'You can now add your releases to the "{page}."': 'You can now add your releases to the "{page}."',
        "You can't assign your own elasticStage ISRC unless it already exists.":
            'Sie können keinen eigenen elasticStage ISRC zuweisen, außer er existiert bereits.',
        "You can't create another seller account. You already have one!":
            'Sie können kein weiteres Verkäuferkonto einrichten. Sie haben bereits eines!',
        "You can't have any more bank accounts in your country!":
            'Sie können keine weiteren Bankkonten in Ihrem Land haben!',
        'You can\'t have more than one page with the same name. If somebody else is already using the same name for a page, you will get a warning, but you can create it as long as you understand that you could be held accountable for any copyright violations. The URL is your unique weblink to your page. It looks like this: <code>elasticstage.com/{Page URL}</code>. You only type the part after the slash without the brackets into the field below. Allowed characters are: a-z, 0-9, and hyphen "-" when not at the start or end. elasticStage always reserves the right to deny certain page names and URLs at any point in time. More info about creating a new page <a href="{url}" target="_blank">here</a>':
            'Sie können nicht mehr als eine Seite mit dem selben Namen haben. Falls jemand anderer bereits den selben Namen für eine Seite verwendet, bekommen Sie eine Warnung, jedoch ist es möglich eine Seite zu erstellen, so lange Sie verstehen, dass Sie für Copyrightverletzungen verantwortlich gemacht werden können. Die URL ist dein einzigartiger Weblink zu ihrer Seite. Sie sieht folgendermaßen aus: <code>elasticstage.com/{Seiten-URL}</code>. Sie geben nur den Teil nach dem Schrägstrich und ohne die Klammern in das Feld unterhalb ein. Erlaubte Zeichen sind: a-z, 0-9 und Bindestrich "-", wenn er nicht am Anfang oder Ende steht. elasticStage behält sich Recht vor, jederzeit bestimmte Seitennamen oder URLs zu verweigern. Mehr Info über die Erstellung von Seiten gibt es <a href="{url}" target="_blank">hier</a>',
        "You can\\'t assign your own elasticStage ISRC unless it already exists.":
            "You can\\'t assign your own elasticStage ISRC unless it already exists.",
        'You cannot remove the default address': 'You cannot remove the default address',
        "You don't have a billing address set up.": 'Sie haben keine Rechnungsadresse angelegt.',
        "You don't have a default delivery address set up.": 'Sie haben keine Standard-Lieferadresse angelegt.',
        'You have entered the wrong details more than three times and have been logged out for security reasons.':
            'Sie haben mehr als dreimal die falschen Daten eingegeben und werden daher aus Sicherheitsgründen ausgeloggt.',
        'You have to provide information for any additional owners with a {share_percent}%+ share.':
            'Sie müssen alle zusätzlichen Besitzer mit einem Anteil von {share_percent}%+ angeben.',
        "You haven't been active for a while - so for your security, we'll move you away.":
            'Sie waren für einige Zeit inaktiv - also werden wir zu Ihrer Sicherheit von hier weggehen.',
        'You must add a valid credit/debit card!': 'Sie müssen eine gültige Kredit- oder Debitkarte anlegen!',
        'You must be logged in or allow Cookies!': 'Sie müssen eingeloggt sein oder Cookies erlauben!',
        'You must enter a code!': 'Sie müssen einen Code eingeben!',
        'You must enter at least a name or business name together with the address. We can only deliver to the countries available from the "Country" select field.':
            'Sie müssen zumindest einen Namen oder Firmennamen, zusammen mit der Adresse eingeben. Wir können nur in jene Länder liefern, die vom "Land"-Selektierfeld ausgewählt werden können.',
        'You must enter your password!': 'Sie müssen Ihr Passwort eingeben!',
        'You must follow the guidelines for artwork precisely and use our templates or your release will be rejected. The EAN-13 barcode and Explicit Warning (if necessary) will be automatically inserted into the artwork at the designated places.':
            'Sie müssen die Richtlinien für die Artwork exakt befolgen und unsere Templates verwenden, sonst wird Ihr Release abgelehnt. Der EAN-13 Barcode und die Explicit-Warnung (falls notwendig) werden automatisch an den vorhergesehenen Stellen in die Artwork eingefügt.',
        'You must provide a composer for a Classical genre.':
            'Sie müssen einen Komponisten für ein klassisches Genre angeben.',
        'You must select a country!': 'Sie müssen ein Land wählen!',
        'You must tick the box first!': 'Sie müssen zuerst das Kästchen ankreuzen!',
        'You must type "delete" to continue!': 'Sie müssen "lösche" tippen, um fortzufahren!',
        'You must type "retire" to continue!': 'Sie müssen "einstellen" tippen, um fortzufahren!',
        'You own this page': '',
        'You took to long to verify your code! Please start again from the beginning.':
            'Sie haben zu lange gebraucht, um den Code zu bestätigen! Bitte beginnen Sie nochmal von vorne.',
        "You've reached the maximum number of songs for this release type!":
            'Sie haben das Maximum an Songs für diese Releasetype erreicht!',
        YouTube: 'YouTube',
        'YouTube Channel': '',
        'YouTube Channels': 'YouTube Channels',
        'YouTube Playlist': 'YouTube Playlist',
        'Your Addresses': 'Ihre Adressen',
        'Your Basket': '',
        'Your Country & Language': '',
        'Your Instagram account is connected.': 'Your Instagram account is connected.',
        'Your Shopping Basket is empty.': 'Ihr Einkaufswagen ist leer.',
        'Your account number/IBAN is invalid!': 'Ihre Kontonummer/IBAN ist ungültig!',
        "Your card isn't registered at the billing address. Change the card or update your billing address by going to your":
            'Ihre Karte ist nicht auf der Rechnungsadresse registriert. Ändern Sie die Karte oder updaten sie die Rechnungsadresse, auf dem',
        'Your code has been confirmed': 'Ihr Code wurde bestätigt!',
        'Your code has expired!': 'Ihr Code is abgelaufen!',
        'Your code is incorrect!': 'Ihr Code ist inkorrekt!',
        'Your contribution request has been accepted.': 'Your contribution request has been accepted.',
        'Your contribution request has been rejected.': 'Your contribution request has been rejected.',
        'Your invitation is expired, please ask elasticStage Ltd. staff for a new one':
            'Your invitation is expired, please ask elasticStage Ltd. staff for a new one',
        'Your mobile phone is verified!': 'Ihr Mobiltelefon ist bestätigt!',
        'Your new one will be: {new_email}': 'Ihre neue wird sein: {new_email}',
        'Your old E-Mail address is: {old_email}': 'Ihre alte Emailadresse ist: {old_email}',
        'Your password has been changed!': 'Ihr Passwort wurde geändert!',
        'Your previous E-Mail address was: {old_email}': 'Ihre alte Emailadresse war: {old_email}',
        'Your record label has been created!': 'Ihr Plattenlabel wurde eingerichtet!',
        'Your record label is now active. You will find a new entry "Record Label" in the menu below your name in the top right, where you can manage your releases, pages and do all label related things.':
            'Ihr Plattenlabel ist nun aktiv. Im Menü unterhalb Ihres Namens (oben rechts) finden Sie nun "Plattenlabel", wo Sie Ihre Releases, Seiten und andere Dinge, die das Label betreffen, verwalten können.',
        'Your request to add releases to the "{page}" page has been rejected.':
            'Your request to add releases to the "{page}" page has been rejected.',
        'Youtube Playlist': 'Youtube Playlist',
        'Zip/Postal Code': 'Postleitzahl',
        Zoom: 'Zoom',
        back: 'zurück',
        'begins with ': '',
        browse: '',
        'click to copy': 'klicke um zu kopieren',
        clone: 'klonen',
        'create a free account': 'eröffne ein Gratiskonto',
        delete: 'löschen',
        'ex. Tax': '',
        found: 'gefunden',
        inc: '',
        'inc. Tax': '',
        'must begin with <code>http://</code> or <code>https://</code>':
            'must begin with <code>http://</code> or <code>https://</code>',
        'song(s)...': '',
        subscribers: '',
        videos: '',
        views: '',
        'you can also use the mousewheel': 'sie können auch das Mausrad verwenden',
        auth: {
            failed: 'Diese Kombination aus Zugangsdaten wurde nicht in unserer Datenbank gefunden.',
            throttle: 'Zu viele Loginversuche. Versuchen Sie es bitte in {seconds} Sekunden nochmal.',
        },
        flash: {
            emailCheck:
                'Bitte check deine Email- und Spamordner und bestätige deine Emailadresse!\\n\\nEs ist nicht möglich sich vorher einzuloggen.',
            emailConfirmed: 'Deine Emailadresse ist bestätigt. Du bist nun eingeloggt.',
            emailChangeConfirmed: 'Deine neue Emailadresse ist bestätigt und nun aktiv!',
            emailNotConfirmed:
                'Deine Emailadresse wurde nicht von dir bestätigt!\\n\\nBitte check deine Email- und Spamordner für: {email}.\\nWir haben dir eine weitere Nachricht zur Bestätigung an diese Adresse gesendet.',
            requestExpired: 'Diese Anfrage ist abgelaufen oder ungültig!',
        },
        notifications: {
            hello: 'Hallo {Name},',
            regards: 'Grüße,',
            'Notify me': 'Benachrichtige mich',
            url_link:
                'Falls du Probleme hast den "{Button}" Button zu drücken, copy und paste diese URL in deinen Webbrowser:',
            passwordReset_subject: 'Reset Passwort',
            passwordReset_message1:
                'Du erhälst dieses E-Mail, da wir eine Anfrage für einen Passwort-Reset für dein Konto bekommen haben.',
            passwordReset_button: 'Reset Passwort',
            passwordReset_message2: 'Falls du keinen Passwortwechsel beantragt hast, ist nichts weiter zu tun.',
            confirmEmail_subject: 'Bestätige E-Mail',
            confirmEmail_message1:
                'Du erhälst dieses E-Mail, da jemand ein neues Konto mit dieser Emailadresse angelegt hat. Bitte klicke auf den "Bestätige E-Mail" Button, um das Konto zu aktivieren und lösche diese Nachricht anschließend. Der Button funktioniert nur einmal.',
            confirmEmail_button: 'Bestätige E-Mail',
            confirmEmail_message2: 'Falls du kein neues Konto eröffnet hast, ist nichts weiter zu tun.',
            changeEmail_subject: 'Bestätige E-Mail Änderung',
            changeEmail_message1: 'Du erhälst dieses E-Mail, da jemand deine Emailadresse wechseln möchte.',
            changeEmail_message1b: 'Deine alte Emailadresse ist: {old_email}',
            changeEmail_message1c: 'Deine neue wird sein: {new_email}',
            changeEmail_message1d:
                'Bitte klicke auf den "Wechsle E-Mail" Button innerhalb von {minutes} minuten, um die Änderung zu aktivieren und lösche diese Nachricht anschließend. Der Button funktioniert nur einmal.',
            changeEmail_button: 'Wechsle E-Mail',
            changeEmail_message2: 'Falls du die Emailadresse nicht gewechselt hast, ist nichts weiter zu tun.',
            changeEmailO_subject: 'E-Mail Änderung',
            changeEmailO_message1: 'Du erhälst dieses E-Mail, da jemand deine Emailadresse gewechselt hat.',
            changeEmailO_message1b: 'Deine alte Emailadresse war: {old_email}',
            changeEmailO_message1c: 'Sie ist nun: {new_email}',
            changeEmailO_message2:
                'Falls du die Emailadresse nicht gewechselt hast, kontaktiere uns auf <a href="mailto:account-details-changed@elasticstage.com">account-details-changed@elasticstage.com</a>.',
        },
        pagination: {
            previous: '&laquo; Zurück',
            next: 'Weiter &raquo;',
        },
        passwords: {
            password: 'Passwörter müssen mindestens 8 Zeichen lang sein und korrekt bestätigt werden.',
            reset: 'Das Passwort wurde zurückgesetzt!',
            sent: 'Passworterinnerung wurde gesendet!',
            throttled: 'Bitte warten Sie, bevor Sie es erneut versuchen.',
            token: 'Der Passwort-Wiederherstellungs-Schlüssel ist ungültig oder abgelaufen.',
            user: 'Es konnte leider kein Nutzer mit dieser E-Mail-Adresse gefunden werden.',
        },
        'validation-inline': {
            accepted: 'Dieses Feld muss akzeptiert werden.',
            active_url: 'Das ist keine gültige Internet-Adresse.',
            after: 'Das muss ein Datum nach dem {date} sein.',
            after_or_equal: 'Das muss ein Datum nach dem {date} oder gleich dem {date} sein.',
            alpha: 'Der Inhalt darf nur aus Buchstaben bestehen.',
            alpha_dash: 'Der Inhalt darf nur aus Buchstaben, Zahlen, Binde- und Unterstrichen bestehen.',
            alpha_num: 'Der Inhalt darf nur aus Buchstaben und Zahlen bestehen.',
            array: 'Dieses Feld muss ein Array sein.',
            before: 'Das muss ein Datum vor dem {date} sein.',
            before_or_equal: 'Das muss ein Datum vor dem {date} oder gleich dem {date} sein.',
            between: {
                numeric: 'Dieser Wert muss zwischen {min} & {max} liegen.',
                file: 'Diese Datei muss zwischen {min} & {max} Kilobytes groß sein.',
                string: 'Diese Zeichenkette muss zwischen {min} & {max} Zeichen lang sein.',
                array: 'Dieser Inhalt muss zwischen {min} & {max} Elemente haben.',
            },
            boolean: "Dieses Feld muss entweder 'true' oder 'false' sein.",
            confirmed: 'Die Bestätigung stimmt nicht überein.',
            date: 'Das ist kein gültiges Datum.',
            date_equals: 'Das Datum muss identisch zu {date} sein.',
            date_format: 'Das Datum entspricht nicht dem gültigen Format für {format}.',
            different: 'Das muss sich von {other} unterscheiden.',
            digits: 'Das muss {digits} Stellen haben.',
            digits_between: 'Das muss zwischen {min} und {max} Stellen haben.',
            dimensions: 'Das Bild hat ungültige Abmessungen.',
            distinct: 'Dieser Wert ist bereits vorhanden.',
            email: 'Das muss eine gültige E-Mail-Adresse sein.',
            ends_with: 'Der Inhalt muss eine der folgenden Endungen aufweisen: {values}',
            exists: 'Der gewählte Wert ist ungültig.',
            file: 'Der Inhalt muss eine Datei sein.',
            filled: 'Dieses Feld muss ausgefüllt sein.',
            gt: {
                numeric: 'Der Wert muss größer als {value} sein.',
                file: 'Die Datei muss größer als {value} Kilobytes sein.',
                string: 'Die Zeichenkette muss länger als {value} Zeichen sein.',
                array: 'Der Inhalt muss mehr als {value} Elemente haben.',
            },
            gte: {
                numeric: 'Der Wert muss größer oder gleich {value} sein.',
                file: 'Die Datei muss größer oder gleich {value} Kilobytes sein.',
                string: 'Die Zeichenkette muss mindestens {value} Zeichen lang sein.',
                array: 'Der Inhalt muss mindestens {value} Elemente haben.',
            },
            image: 'Das muss ein Bild sein.',
            in: 'Der gewählte Wert ist ungültig.',
            in_array: 'Der gewählte Wert kommt nicht in {other} vor.',
            integer: 'Das muss eine ganze Zahl sein.',
            ip: 'Das muss eine gültige IP-Adresse sein.',
            ipv4: 'Das muss eine gültige IPv4-Adresse sein.',
            ipv6: 'Das muss eine gültige IPv6-Adresse sein.',
            json: 'Das muss ein gültiger JSON-String sein.',
            lt: {
                numeric: 'Der Wert muss kleiner als {value} sein.',
                file: 'Die Datei muss kleiner als {value} Kilobytes sein.',
                string: 'Die Zeichenkette muss kürzer als {value} Zeichen sein.',
                array: 'Der Inhalt muss weniger als {value} Elemente haben.',
            },
            lte: {
                numeric: 'Der Wert muss kleiner oder gleich {value} sein.',
                file: 'Die Datei muss kleiner oder gleich {value} Kilobytes sein.',
                string: 'Die Zeichenkette darf maximal {value} Zeichen lang sein.',
                array: 'Der Inhalt darf maximal {value} Elemente haben.',
            },
            max: {
                numeric: 'Der Wert darf maximal {max} sein.',
                file: 'Die Datei darf maximal {max} Kilobytes groß sein.',
                string: 'Die Zeichenkette darf maximal {max} Zeichen haben.',
                array: 'Der Inhalt darf maximal {max} Elemente haben.',
            },
            mimes: 'Das muss den Dateityp {values} haben.',
            mimetypes: 'Das muss den Dateityp {values} haben.',
            min: {
                numeric: 'Der Wert muss mindestens {min} sein.',
                file: 'Die Datei muss mindestens {min} Kilobytes groß sein.',
                string: 'Die Zeichenkette muss mindestens {min} Zeichen lang sein.',
                array: 'Der Inhalt muss mindestens {min} Elemente haben.',
            },
            multiple_of: 'Der Wert muss ein Vielfaches von {value} sein.',
            not_in: 'Der gewählte Wert ist ungültig.',
            not_regex: 'Dieses Format ist ungültig.',
            numeric: 'Das muss eine Zahl sein.',
            password: 'Das Passwort ist falsch.',
            present: 'Dieses Feld muss vorhanden sein.',
            regex: 'Dieses Format ist ungültig.',
            required: 'Dieses Feld muss ausgefüllt werden.',
            required_if: 'Dieses Feld muss ausgefüllt werden, wenn {other} den Wert {value} hat.',
            required_unless: 'Dieses Feld muss ausgefüllt werden, wenn {other} nicht den Wert {values} hat.',
            required_with: 'Dieses Feld muss ausgefüllt werden, wenn {values} ausgefüllt wurde.',
            required_with_all: 'Dieses Feld muss ausgefüllt werden, wenn {values} ausgefüllt wurde.',
            required_without: 'Dieses Feld muss ausgefüllt werden, wenn {values} nicht ausgefüllt wurde.',
            required_without_all:
                'Dieses Feld muss ausgefüllt werden, wenn keines der Felder {values} ausgefüllt wurde.',
            same: 'Der Wert von diesem Feld und muss mit demjenigen von {other} übereinstimmen.',
            size: {
                numeric: 'Der Wert muss gleich {size} sein.',
                file: 'Die Datei muss {size} Kilobyte groß sein.',
                string: 'Die Zeichenkette muss {size} Zeichen lang sein.',
                array: 'Der Inhalt muss genau {size} Elemente haben.',
            },
            starts_with: 'Der Inhalt muss einen der folgenden Anfänge aufweisen: {values}',
            string: 'Das muss ein String sein.',
            timezone: 'Das muss eine gültige Zeitzone sein.',
            unique: 'Das ist bereits vergeben.',
            uploaded: 'Das konnte nicht hochgeladen werden.',
            url: 'Das muss eine URL sein.',
            uuid: 'Das muss eine UUID sein.',
            custom: {
                'attribute-name': {
                    'rule-name': 'custom-message',
                },
            },
        },
        validation: {
            active_url: '{attribute} ist keine gültige Internet-Adresse.',
            confirmed: '{attribute} stimmt nicht mit der Bestätigung überein.',
            date: '{attribute} muss ein gültiges Datum sein.',
            date_format: '{attribute} entspricht nicht dem gültigen Format für {format}.',
            different: '{attribute} und {other} müssen sich unterscheiden.',
            dimensions: '{attribute} hat ungültige Bildabmessungen.',
            email: '{attribute} muss eine gültige E-Mail-Adresse sein.',
            exists: 'Der gewählte Wert für {attribute} ist ungültig.',
            filled: '{attribute} muss ausgefüllt sein.',
            in: 'Der gewählte Wert für {attribute} ist ungültig.',
            in_array: 'Der gewählte Wert für {attribute} kommt nicht in {other} vor.',
            ip: '{attribute} muss eine gültige IP-Adresse sein.',
            ipv4: '{attribute} muss eine gültige IPv4-Adresse sein.',
            ipv6: '{attribute} muss eine gültige IPv6-Adresse sein.',
            json: '{attribute} muss ein gültiger JSON-String sein.',
            not_in: 'Der gewählte Wert für {attribute} ist ungültig.',
            not_regex: '{attribute} hat ein ungültiges Format.',
            regex: '{attribute} Format ist ungültig.',
            required: '{attribute} muss ausgefüllt werden.',
            required_if: '{attribute} muss ausgefüllt werden, wenn {other} den Wert {value} hat.',
            required_unless: '{attribute} muss ausgefüllt werden, wenn {other} nicht den Wert {values} hat.',
            required_with: '{attribute} muss ausgefüllt werden, wenn {values} ausgefüllt wurde.',
            required_with_all: '{attribute} muss ausgefüllt werden, wenn {values} ausgefüllt wurde.',
            required_without: '{attribute} muss ausgefüllt werden, wenn {values} nicht ausgefüllt wurde.',
            required_without_all:
                '{attribute} muss ausgefüllt werden, wenn keines der Felder {values} ausgefüllt wurde.',
            same: '{attribute} und {other} müssen übereinstimmen.',
            timezone: '{attribute} muss eine gültige Zeitzone sein.',
            unique: '{attribute} ist bereits vergeben.',
            custom: {
                'attribute-name': {
                    'rule-name': 'custom-message',
                },
            },
            attributes: {
                a_line1: 'Adresszeile 1',
                a_line2: 'Adresszeile 2',
                a_city: 'Stadt',
                a_state: 'Bundesland',
                a_postal_code: 'Postleitzahl',
                a_country: 'Land',
                about: 'Über',
                account_type: 'Kontoart',
                additional_artists: 'Weitere Künstler',
                address_line1: 'Adresszeile 1',
                address_line2: 'Adresszeile 2',
                address_city: 'Stadt/Dorf',
                address_state: 'Bundesland',
                address_postal_code: 'Postleitzahl',
                address_country: 'Land',
                business_address_line1: 'Adresszeile 1',
                business_address_line2: 'Adresszeile 2',
                business_address_city: 'Stadt/Dorf',
                business_address_state: 'Bundesland',
                business_address_postal_code: 'Postleitzahl',
                business_address_country: 'Land',
                business: 'Firma',
                business_name: 'Firmenname',
                c_year: '© Jahr',
                c_holder: '© Besitzer',
                catalog_no: 'Katalognummer',
                content_title: 'Titel',
                dob: 'Geburtsdatum',
                ean: 'EAN-13 Barcode',
                email: 'E-Mail Adresse',
                eu_vat_number: 'EU-Steuernummer',
                event_name: 'Eventname',
                facebook_link: 'Facebook',
                formatted_address: 'Adresse',
                grid: 'GRid',
                instagram_link: 'Instagram',
                label: 'Plattenlabel',
                maiden_name: 'Mädchenname',
                main_genre: 'Hauptgenre',
                mobile_phone: 'Mobiltelefon',
                p_year: '℗ Jahr',
                p_holder: '℗ Besitzer',
                panel_name: 'Panelname',
                page_id: 'Zugewiesene Seite',
                page_name: 'Seitenname',
                page_url: 'Seiten-URL',
                paypal_account: 'PayPal Auszahlungskonto',
                personal_id_number: 'Ausweisnummer',
                preorder_date: 'Vorbestellungsdatum',
                primary_artist: 'Hauptkünstler',
                release_date: 'Veröffentlichungsdatum',
                release_title: 'Releasetitel',
                release_type_id: 'Releasetype',
                royalty: 'Lizenzgebühr',
                shipping_phone: 'Telefon',
                ssn_last_4: 'SSN',
                sub_genre: 'Subgenre',
                support_act: 'Support-Act',
                twitter_link: 'Twitter',
                url: 'URL',
                url_link: 'Webseite',
                venue: 'Venue',
                youtube_link: 'YouTube',
                name: 'Name',
                username: 'Benutzername',
                first_name: 'Vorname',
                last_name: 'Nachname',
                password: 'Passwort',
                password_confirmation: 'Passwort Bestätigung',
                city: 'Stadt',
                country: 'Land',
                address: 'Adresse',
                phone: 'Telefonnummer',
                mobile: 'Handynummer',
                age: 'Alter',
                sex: 'Geschlecht',
                gender: 'Geschlecht',
                day: 'Tag',
                month: 'Monat',
                year: 'Jahr',
                hour: 'Stunde',
                minute: 'Minute',
                second: 'Sekunde',
                title: 'Titel',
                content: 'Inhalt',
                description: 'Beschreibung',
                excerpt: 'Auszug',
                date: 'Datum',
                time: 'Uhrzeit',
                available: 'verfügbar',
                size: 'Größe',
            },
            accepted: '{attribute} muss akzeptiert werden.',
            after: '{attribute} muss ein Datum nach dem {date} sein.',
            after_or_equal: '{attribute} muss ein Datum nach dem {date} oder gleich dem {date} sein.',
            alpha: '{attribute} darf nur aus Buchstaben bestehen.',
            alpha_dash: '{attribute} darf nur aus Buchstaben, Zahlen, Binde- und Unterstrichen bestehen.',
            alpha_num: '{attribute} darf nur aus Buchstaben und Zahlen bestehen.',
            array: '{attribute} muss ein Array sein.',
            before: '{attribute} muss ein Datum vor dem {date} sein.',
            before_or_equal: '{attribute} muss ein Datum vor dem {date} oder gleich dem {date} sein.',
            between: {
                numeric: '{attribute} muss zwischen {min} & {max} liegen.',
                file: '{attribute} muss zwischen {min} & {max} Kilobytes groß sein.',
                string: '{attribute} muss zwischen {min} & {max} Zeichen lang sein.',
                array: '{attribute} muss zwischen {min} & {max} Elemente haben.',
            },
            boolean: "{attribute} muss entweder 'true' oder 'false' sein.",
            date_equals: '{attribute} muss ein Datum gleich {date} sein.',
            digits: '{attribute} muss {digits} Stellen haben.',
            digits_between: '{attribute} muss zwischen {min} und {max} Stellen haben.',
            distinct: '{attribute} beinhaltet einen bereits vorhandenen Wert.',
            ends_with: '{attribute} muss eine der folgenden Endungen aufweisen: {values}',
            file: '{attribute} muss eine Datei sein.',
            gt: {
                numeric: '{attribute} muss größer als {value} sein.',
                file: '{attribute} muss größer als {value} Kilobytes sein.',
                string: '{attribute} muss länger als {value} Zeichen sein.',
                array: '{attribute} muss mehr als {value} Elemente haben.',
            },
            gte: {
                numeric: '{attribute} muss größer oder gleich {value} sein.',
                file: '{attribute} muss größer oder gleich {value} Kilobytes sein.',
                string: '{attribute} muss mindestens {value} Zeichen lang sein.',
                array: '{attribute} muss mindestens {value} Elemente haben.',
            },
            image: '{attribute} muss ein Bild sein.',
            integer: '{attribute} muss eine ganze Zahl sein.',
            lt: {
                numeric: '{attribute} muss kleiner als {value} sein.',
                file: '{attribute} muss kleiner als {value} Kilobytes sein.',
                string: '{attribute} muss kürzer als {value} Zeichen sein.',
                array: '{attribute} muss weniger als {value} Elemente haben.',
            },
            lte: {
                numeric: '{attribute} muss kleiner oder gleich {value} sein.',
                file: '{attribute} muss kleiner oder gleich {value} Kilobytes sein.',
                string: '{attribute} darf maximal {value} Zeichen lang sein.',
                array: '{attribute} darf maximal {value} Elemente haben.',
            },
            max: {
                numeric: '{attribute} darf maximal {max} sein.',
                file: '{attribute} darf maximal {max} Kilobytes groß sein.',
                string: '{attribute} darf maximal {max} Zeichen haben.',
                array: '{attribute} darf maximal {max} Elemente haben.',
            },
            mimes: '{attribute} muss den Dateityp {values} haben.',
            mimetypes: '{attribute} muss den Dateityp {values} haben.',
            min: {
                numeric: '{attribute} muss mindestens {min} sein.',
                file: '{attribute} muss mindestens {min} Kilobytes groß sein.',
                string: '{attribute} muss mindestens {min} Zeichen lang sein.',
                array: '{attribute} muss mindestens {min} Elemente haben.',
            },
            multiple_of: '{attribute} muss ein Vielfaches von {value} sein.',
            numeric: '{attribute} muss eine Zahl sein.',
            password: 'Das Passwort ist falsch.',
            present: '{attribute} muss vorhanden sein.',
            size: {
                numeric: '{attribute} muss gleich {size} sein.',
                file: '{attribute} muss {size} Kilobyte groß sein.',
                string: '{attribute} muss {size} Zeichen lang sein.',
                array: '{attribute} muss genau {size} Elemente haben.',
            },
            starts_with: '{attribute} muss mit einem der folgenden Anfänge aufweisen: {values}',
            string: '{attribute} muss ein String sein.',
            uploaded: '{attribute} konnte nicht hochgeladen werden.',
            url: '{attribute} muss eine URL sein.',
            uuid: '{attribute} muss ein UUID sein.',
        },
    },
    en: {
        auth: {
            failed: 'Invalid credentials or E-Mail not verified.',
            throttle: 'Too many login attempts. Please try again in {seconds} seconds.',
        },
        flash: {
            emailCheck:
                "Please check your E-Mail and spam folders to confirm your E-Mail address!\\n\\nYou won't be able to log in until you do.",
            emailConfirmed: 'Your email is confirmed. You are now logged in.',
            emailChangeConfirmed: 'Your new Email-address has been confirmed and is now active!',
            emailNotConfirmed:
                "You haven't verified your E-Mail!\\n\\nPlease check your E-Mail and spam folders at: {email}.\\nWe've sent another confirmation E-Mail to this address.",
            requestExpired: 'This request has expired or is invalid!',
        },
        notifications: {
            hello: 'Hello {Name},',
            regards: 'Regards,',
            url_link:
                'If you’re having trouble clicking the "{Button}" button, copy and paste the URL below into your web browser:',
            passwordReset_subject: 'Reset Password',
            passwordReset_message1:
                'You are receiving this email because we received a password reset request for your account.',
            passwordReset_button: 'Reset Password',
            passwordReset_message2: 'If you did not request a password reset, no further action is required.',
            confirmEmail_subject: 'Confirm E-Mail',
            confirmEmail_message1:
                'You are receiving this email because someone created a new account with your E-Mail address. Please click the Confirm E-Mail button within 24 hours to activate your account and delete the message afterwards. It will work only once.',
            confirmEmail_button: 'Confirm E-Mail',
            confirmEmail_message2: 'If you did not create a new account, no further action is required.',
            changeEmail_subject: 'Confirm E-Mail Change',
            changeEmail_message1: 'You are receiving this email because someone wants to change your E-Mail address.',
            changeEmail_message1b: 'Your old E-Mail address is: {old_email}',
            changeEmail_message1c: 'Your new one will be: {new_email}',
            changeEmail_message1d:
                'Please click the Change E-Mail button within one {minutes} minutes to activate the change and delete the message afterwards. It will work only once.',
            changeEmail_button: 'Change E-Mail',
            changeEmail_message2: 'If you did not change the E-Mail address, no further action is required.',
            changeEmailO_subject: 'E-Mail Change',
            changeEmailO_message1: 'You are receiving this email because someone changed your E-Mail address.',
            changeEmailO_message1b: 'Your previous E-Mail address was: {old_email}',
            changeEmailO_message1c: "It's now: {new_email}",
            changeEmailO_message2:
                'If you did not change the E-Mail address, please contact us at <a href="mailto:account-details-changed@elasticstage.com">account-details-changed@elasticstage.com</a>.',
        },
        pagination: {
            previous: '&laquo; Previous',
            next: 'Next &raquo;',
        },
        passwords: {
            password: 'Passwords must be at least eight characters and match the confirmation.',
            reset: 'Your password has been updated!',
            sent: 'We have e-mailed your password reset link!',
            token: 'This password reset token is invalid.',
            user: "We can't find a user with that e-mail address.",
        },
        validation: {
            accepted: 'The {attribute} must be accepted.',
            active_url: 'The {attribute} is not a valid URL.',
            after: 'The {attribute} must be a date after {date}.',
            after_or_equal: 'The {attribute} must be a date after or equal to {date}.',
            alpha: 'The {attribute} may only contain letters.',
            alpha_dash: 'The {attribute} may only contain letters, numbers, dashes and underscores.',
            alpha_num: 'The {attribute} may only contain letters and numbers.',
            array: 'The {attribute} must be an array.',
            before: 'The {attribute} must be a date before {date}.',
            before_or_equal: 'The {attribute} must be a date before or equal to {date}.',
            between: {
                numeric: 'The {attribute} must be between {min} and {max}.',
                file: 'The {attribute} must be between {min} and {max} kilobytes.',
                string: 'The {attribute} must be between {min} and {max} characters.',
                array: 'The {attribute} must have between {min} and {max} items.',
            },
            boolean: 'The {attribute} field must be true or false.',
            confirmed: 'The {attribute} confirmation does not match.',
            date: 'The {attribute} is not a valid date.',
            date_equals: 'The {attribute} must be a date equal to {date}.',
            date_format: 'The {attribute} does not match the format {format}.',
            different: 'The {attribute} and {other} must be different.',
            digits: 'The {attribute} must be {digits} digits.',
            digits_between: 'The {attribute} must be between {min} and {max} digits.',
            dimensions: 'The {attribute} has invalid image dimensions.',
            distinct: 'The {attribute} field has a duplicate value.',
            email: 'The {attribute} must be a valid email address.',
            exists: 'The selected {attribute} is invalid.',
            file: 'The {attribute} must be a file.',
            filled: 'The {attribute} field must have a value.',
            gt: {
                numeric: 'The {attribute} must be greater than {value}.',
                file: 'The {attribute} must be greater than {value} kilobytes.',
                string: 'The {attribute} must be greater than {value} characters.',
                array: 'The {attribute} must have more than {value} items.',
            },
            gte: {
                numeric: 'The {attribute} must be greater than or equal {value}.',
                file: 'The {attribute} must be greater than or equal {value} kilobytes.',
                string: 'The {attribute} must be greater than or equal {value} characters.',
                array: 'The {attribute} must have {value} items or more.',
            },
            image: 'The {attribute} must be an image.',
            in: 'The selected {attribute} is invalid.',
            in_array: 'The {attribute} field does not exist in {other}.',
            integer: 'The {attribute} must be an integer.',
            ip: 'The {attribute} must be a valid IP address.',
            ipv4: 'The {attribute} must be a valid IPv4 address.',
            ipv6: 'The {attribute} must be a valid IPv6 address.',
            json: 'The {attribute} must be a valid JSON string.',
            lt: {
                numeric: 'The {attribute} must be less than {value}.',
                file: 'The {attribute} must be less than {value} kilobytes.',
                string: 'The {attribute} must be less than {value} characters.',
                array: 'The {attribute} must have less than {value} items.',
            },
            lte: {
                numeric: 'The {attribute} must be less than or equal {value}.',
                file: 'The {attribute} must be less than or equal {value} kilobytes.',
                string: 'The {attribute} must be less than or equal {value} characters.',
                array: 'The {attribute} must not have more than {value} items.',
            },
            max: {
                numeric: 'The {attribute} may not be greater than {max}.',
                file: 'The {attribute} may not be greater than {max} kilobytes.',
                string: 'The {attribute} may not be greater than {max} characters.',
                array: 'The {attribute} may not have more than {max} items.',
            },
            mimes: 'The {attribute} must be a file of type: {values}.',
            mimetypes: 'The {attribute} must be a file of type: {values}.',
            min: {
                numeric: 'The {attribute} must be at least {min}.',
                file: 'The {attribute} must be at least {min} kilobytes.',
                string: 'The {attribute} must be at least {min} characters.',
                array: 'The {attribute} must have at least {min} items.',
            },
            not_in: 'The selected {attribute} is invalid.',
            not_regex: 'The {attribute} format is invalid.',
            numeric: 'The {attribute} must be a number.',
            present: 'The {attribute} field must be present.',
            regex: 'The {attribute} format is invalid.',
            required: 'The {attribute} field is required.',
            required_if: 'The {attribute} field is required when {other} is {value}.',
            required_unless: 'The {attribute} field is required unless {other} is in {values}.',
            required_with: 'The {attribute} field is required when {values} is present.',
            required_with_all: 'The {attribute} field is required when {values} are present.',
            required_without: 'The {attribute} field is required when {values} is not present.',
            required_without_all: 'The {attribute} field is required when none of {values} are present.',
            same: 'The {attribute} and {other} must match.',
            size: {
                numeric: 'The {attribute} must be {size}.',
                file: 'The {attribute} must be {size} kilobytes.',
                string: 'The {attribute} must be {size} characters.',
                array: 'The {attribute} must contain {size} items.',
            },
            starts_with: 'The {attribute} must start with one of the following: {values}',
            string: 'The {attribute} must be a string.',
            timezone: 'The {attribute} must be a valid zone.',
            unique: 'The {attribute} has already been taken.',
            uploaded: 'The {attribute} failed to upload.',
            url: 'The {attribute} format is invalid.',
            uuid: 'The {attribute} must be a valid UUID.',
            custom: {
                'attribute-name': {
                    'rule-name': 'custom-message',
                },
            },
            attributes: {
                a_line1: 'Address Line 1',
                a_line2: 'Address Line 2',
                a_city: 'City',
                a_state: 'State',
                a_postal_code: 'Postal Code',
                a_country: 'Country',
                about: 'About',
                account_type: 'Account Type',
                additional_artists: 'Additional Artists',
                address_line1: 'Address Line 1',
                address_line2: 'Address Line 2',
                address_city: 'Town/Village',
                address_state: 'State',
                address_postal_code: 'Zip/Postal Code',
                address_country: 'Country',
                address_country_iso: 'Country',
                business_address_line1: 'Address Line 1',
                business_address_line2: 'Address Line 2',
                business_address_city: 'Town/Village',
                business_address_state: 'State',
                business_address_postal_code: 'Zip/Postal Code',
                business_address_country: 'Country',
                business: 'Business',
                business_name: 'Business Name',
                c_year: '© Year',
                c_holder: '© Holder',
                catalog_no: 'Catalog Number',
                content_title: 'Title',
                date: 'Date',
                description: 'Description',
                dob: 'Date of Birth',
                ean: 'EAN-13 Barcode',
                email: 'E-Mail Address',
                eu_vat_number: 'EU VAT-Number',
                event_name: 'Event Name',
                facebook_link: 'Facebook',
                first_name: 'First Name',
                formatted_address: 'Address',
                gender: 'Gender',
                grid: 'GRid',
                instagram_link: 'Instagram',
                label: 'Record Label',
                last_name: 'Last Name',
                maiden_name: 'Maiden Name',
                main_genre: 'Main Genre',
                mobile_phone: 'Mobile Phone',
                name: 'Name',
                p_year: '℗ Year',
                p_holder: '℗ Holder',
                panel_name: 'Panel Name',
                password: 'Password',
                page_id: 'Assigned Page',
                page_name: 'Page Name',
                page_url: 'Page URL',
                paypal_account: 'PayPal Payouts Account',
                personal_id_number: 'ID number',
                preorder_date: 'Pre-order Date',
                price_level: 'Price Level',
                primary_artist: 'Primary Artist',
                release_date: 'Release Date',
                release_title: 'Release Title',
                release_type_id: 'Release Type',
                royalty: 'Royalty',
                shipping_phone: 'Phone',
                spotify_link: 'Spotify',
                ssn_last_4: 'SSN',
                sub_genre: 'Sub Genre',
                support_act: 'Supporting Act',
                time: 'Time',
                title: 'Title',
                twitter_link: 'Twitter',
                url: 'URL',
                url_link: 'Web Page',
                venue: 'Venue',
                youtube_link: 'YouTube',
            },
        },
    },
    en_GB: {
        auth: {
            failed: 'Invalid credentials or E-Mail not verified.',
            throttle: 'Too many login attempts. Please try again in {seconds} seconds.',
        },
        flash: {
            emailCheck:
                "Please check your E-Mail and spam folders to confirm your E-Mail address!\\n\\nYou won't be able to log in until you do.",
            emailConfirmed: 'Your email is confirmed. You are now logged in.',
            emailChangeConfirmed: 'Your new Email-address has been confirmed and is now active!',
            emailNotConfirmed:
                "You haven't verified your E-Mail!\\n\\nPlease check your E-Mail and spam folders at: {email}.\\nWe've sent another confirmation E-Mail to this address.",
            requestExpired: 'This request has expired or is invalid!,',
        },
        notifications: {
            hello: 'Hello {Name},',
            regards: 'Regards,',
            url_link:
                'If you’re having trouble clicking the "{Button}" button, copy and paste the URL below into your web browser:',
            passwordReset_subject: 'Reset Password',
            passwordReset_message1:
                'You are receiving this email because we received a password reset request for your account.',
            passwordReset_button: 'Reset Password',
            passwordReset_message2: 'If you did not request a password reset, no further action is required.',
            confirmEmail_subject: 'Confirm E-Mail',
            confirmEmail_message1:
                'You are receiving this email because someone created a new account with your E-Mail address. Please click the Confirm E-Mail button within 24 hours to activate your account and delete the message afterwards. It will work only once.',
            confirmEmail_button: 'Confirm E-Mail',
            confirmEmail_message2: 'If you did not create a new account, no further action is required.',
            changeEmail_subject: 'Confirm E-Mail Change',
            changeEmail_message1: 'You are receiving this email because someone wants to change your E-Mail address.',
            changeEmail_message1b: 'Your old E-Mail address is: {old_email}',
            changeEmail_message1c: 'Your new one will be: {new_email}',
            changeEmail_message1d:
                'Please click the Change E-Mail button within {minutes} minutes to activate the change and delete the message afterwards. It will work only once.',
            changeEmail_button: 'Change E-Mail',
            changeEmail_message2: 'If you did not change the E-Mail address, no further action is required.',
            changeEmailO_subject: 'E-Mail Change',
            changeEmailO_message1: 'You are receiving this email because someone changed your E-Mail address.',
            changeEmailO_message1b: 'Your previous E-Mail address was: {old_email}',
            changeEmailO_message1c: "It's now: {new_email}",
            changeEmailO_message2:
                'If you did not change the E-Mail address, please contact us at <a href="mailto:account-details-changed@elasticstage.com">account-details-changed@elasticstage.com</a>.',
        },
        pagination: {
            previous: '&laquo; Previous',
            next: 'Next &raquo;',
        },
        passwords: {
            password: 'Passwords must be at least eight characters and match the confirmation.',
            reset: 'Your password has been updated!',
            sent: 'We have e-mailed your password reset link!',
            token: 'This password reset token is invalid.',
            user: "We can't find a user with that e-mail address..',",
        },
        validation: {
            accepted: 'The {attribute} must be accepted.',
            active_url: 'The {attribute} is not a valid URL.',
            after: 'The {attribute} must be a date after {date}.',
            after_or_equal: 'The {attribute} must be a date after or equal to {date}.',
            alpha: 'The {attribute} may only contain letters.',
            alpha_dash: 'The {attribute} may only contain letters, numbers, dashes and underscores.',
            alpha_num: 'The {attribute} may only contain letters and numbers.',
            array: 'The {attribute} must be an array.',
            before: 'The {attribute} must be a date before {date}.',
            before_or_equal: 'The {attribute} must be a date before or equal to {date}.',
            between: {
                numeric: 'The {attribute} must be between {min} and {max}.',
                file: 'The {attribute} must be between {min} and {max} kilobytes.',
                string: 'The {attribute} must be between {min} and {max} characters.',
                array: 'The {attribute} must have between {min} and {max} items.',
            },
            boolean: 'The {attribute} field must be true or false.',
            confirmed: 'The {attribute} confirmation does not match.',
            date: 'The {attribute} is not a valid date.',
            date_equals: 'The {attribute} must be a date equal to {date}.',
            date_format: 'The {attribute} does not match the format {format}.',
            different: 'The {attribute} and {other} must be different.',
            digits: 'The {attribute} must be {digits} digits.',
            digits_between: 'The {attribute} must be between {min} and {max} digits.',
            dimensions: 'The {attribute} has invalid image dimensions.',
            distinct: 'The {attribute} field has a duplicate value.',
            email: 'The {attribute} must be a valid email address.',
            exists: 'The selected {attribute} is invalid.',
            file: 'The {attribute} must be a file.',
            filled: 'The {attribute} field must have a value.',
            gt: {
                numeric: 'The {attribute} must be greater than {value}.',
                file: 'The {attribute} must be greater than {value} kilobytes.',
                string: 'The {attribute} must be greater than {value} characters.',
                array: 'The {attribute} must have more than {value} items.',
            },
            gte: {
                numeric: 'The {attribute} must be greater than or equal {value}.',
                file: 'The {attribute} must be greater than or equal {value} kilobytes.',
                string: 'The {attribute} must be greater than or equal {value} characters.',
                array: 'The {attribute} must have {value} items or more.',
            },
            image: 'The {attribute} must be an image.',
            in: 'The selected {attribute} is invalid.',
            in_array: 'The {attribute} field does not exist in {other}.',
            integer: 'The {attribute} must be an integer.',
            ip: 'The {attribute} must be a valid IP address.',
            ipv4: 'The {attribute} must be a valid IPv4 address.',
            ipv6: 'The {attribute} must be a valid IPv6 address.',
            json: 'The {attribute} must be a valid JSON string.',
            lt: {
                numeric: 'The {attribute} must be less than {value}.',
                file: 'The {attribute} must be less than {value} kilobytes.',
                string: 'The {attribute} must be less than {value} characters.',
                array: 'The {attribute} must have less than {value} items.',
            },
            lte: {
                numeric: 'The {attribute} must be less than or equal {value}.',
                file: 'The {attribute} must be less than or equal {value} kilobytes.',
                string: 'The {attribute} must be less than or equal {value} characters.',
                array: 'The {attribute} must not have more than {value} items.',
            },
            max: {
                numeric: 'The {attribute} may not be greater than {max}.',
                file: 'The {attribute} may not be greater than {max} kilobytes.',
                string: 'The {attribute} may not be greater than {max} characters.',
                array: 'The {attribute} may not have more than {max} items.',
            },
            mimes: 'The {attribute} must be a file of type: {values}.',
            mimetypes: 'The {attribute} must be a file of type: {values}.',
            min: {
                numeric: 'The {attribute} must be at least {min}.',
                file: 'The {attribute} must be at least {min} kilobytes.',
                string: 'The {attribute} must be at least {min} characters.',
                array: 'The {attribute} must have at least {min} items.',
            },
            not_in: 'The selected {attribute} is invalid.',
            not_regex: 'The {attribute} format is invalid.',
            numeric: 'The {attribute} must be a number.',
            present: 'The {attribute} field must be present.',
            regex: 'The {attribute} format is invalid.',
            required: 'The {attribute} field is required.',
            required_if: 'The {attribute} field is required when {other} is {value}.',
            required_unless: 'The {attribute} field is required unless {other} is in {values}.',
            required_with: 'The {attribute} field is required when {values} is present.',
            required_with_all: 'The {attribute} field is required when {values} are present.',
            required_without: 'The {attribute} field is required when {values} is not present.',
            required_without_all: 'The {attribute} field is required when none of {values} are present.',
            same: 'The {attribute} and {other} must match.',
            size: {
                numeric: 'The {attribute} must be {size}.',
                file: 'The {attribute} must be {size} kilobytes.',
                string: 'The {attribute} must be {size} characters.',
                array: 'The {attribute} must contain {size} items.',
            },
            starts_with: 'The {attribute} must start with one of the following: {values}',
            string: 'The {attribute} must be a string.',
            timezone: 'The {attribute} must be a valid zone.',
            unique: 'The {attribute} has already been taken.',
            uploaded: 'The {attribute} failed to upload.',
            url: 'The {attribute} format is invalid.',
            uuid: 'The {attribute} must be a valid UUID.',
            custom: {
                'attribute-name': {
                    'rule-name': 'custom-message',
                },
            },
            attributes: {
                a_line1: 'Address Line 1',
                a_line2: 'Address Line 2',
                a_city: 'City',
                a_state: 'State',
                a_postal_code: 'Postal Code',
                a_country: 'Country',
                about: 'About',
                account_type: 'Account Type',
                additional_artists: 'Additional Artists',
                address_line1: 'Address Line 1',
                address_line2: 'Address Line 2',
                address_city: 'Town/Village',
                address_state: 'State',
                address_postal_code: 'Zip/Postal Code',
                address_country: 'Country',
                business_address_line1: 'Address Line 1',
                business_address_line2: 'Address Line 2',
                business_address_city: 'Town/Village',
                business_address_state: 'State',
                business_address_postal_code: 'Zip/Postal Code',
                business_address_country: 'Country',
                business: 'Business',
                business_name: 'Business Name',
                c_year: '© Year',
                c_holder: '© Holder',
                catalog_no: 'Catalog Number',
                content_title: 'Title',
                date: 'Date',
                description: 'Description',
                dob: 'Date of Birth',
                ean: 'EAN-13 Barcode',
                email: 'E-Mail Address',
                eu_vat_number: 'EU VAT-Number',
                event_name: 'Event Name',
                facebook_link: 'Facebook',
                first_name: 'First Name',
                formatted_address: 'Address',
                gender: 'Gender',
                grid: 'GRid',
                instagram_link: 'Instagram',
                label: 'Record Label',
                last_name: 'Last Name',
                maiden_name: 'Maiden Name',
                main_genre: 'Main Genre',
                mobile_phone: 'Mobile Phone',
                name: 'Name',
                p_year: '℗ Year',
                p_holder: '℗ Holder',
                panel_name: 'Panel Name',
                password: 'Password',
                page_id: 'Assigned Page',
                page_name: 'Page Name',
                page_url: 'Page URL',
                paypal_account: 'PayPal Payouts Account',
                personal_id_number: 'ID number',
                preorder_date: 'Pre-order Date',
                primary_artist: 'Primary Artist',
                release_date: 'Release Date',
                release_title: 'Release Title',
                release_type_id: 'Release Type',
                royalty: 'Royalty',
                shipping_phone: 'Phone',
                spotify_link: 'Spotify',
                ssn_last_4: 'SSN',
                sub_genre: 'Sub Genre',
                support_act: 'Supporting Act',
                time: 'Time',
                title: 'Title',
                twitter_link: 'Twitter',
                url: 'URL',
                url_link: 'Web Page',
                venue: 'Venue',
                youtube_link: 'YouTube',
            },
        },
    },
    en_US: {
        auth: {
            failed: 'Invalid credentials or E-Mail not verified.',
            throttle: 'Too many login attempts. Please try again in {seconds} seconds.',
        },
        flash: {
            emailCheck:
                "Please check your E-Mail and spam folders to confirm your E-Mail address!\\n\\nYou won't be able to log in until you do.",
            emailConfirmed: 'Your email is confirmed. You are now logged in.',
            emailChangeConfirmed: 'Your new Email-address has been confirmed and is now active!',
            emailNotConfirmed:
                "You haven't verified your E-Mail!\\n\\nPlease check your E-Mail and spam folders at: {email}.\\nWe've sent another confirmation E-Mail to this address.",
            requestExpired: 'This request has expired or is invalid!',
        },
        notifications: {
            hello: 'Hi {Name},',
            regards: 'Regards,',
            url_link:
                'If you’re having trouble clicking the "{Button}" button, copy and paste the URL below into your web browser:',
            passwordReset_subject: 'Reset Password',
            passwordReset_message1:
                'You are receiving this email because we received a password reset request for your account.',
            passwordReset_button: 'Reset Password',
            passwordReset_message2: 'If you did not request a password reset, no further action is required.',
            confirmEmail_subject: 'Confirm E-Mail',
            confirmEmail_message1:
                'You are receiving this email because someone created a new account with your E-Mail address. Please click the Confirm E-Mail button within 24 hours to activate your account and delete the message afterwards. It will work only once.',
            confirmEmail_button: 'Confirm E-Mail',
            confirmEmail_message2: 'If you did not create a new account, no further action is required.',
            changeEmail_subject: 'Confirm E-Mail Change',
            changeEmail_message1: 'You are receiving this email because someone wants to change your E-Mail address.',
            changeEmail_message1b: 'Your old E-Mail address is: {old_email}',
            changeEmail_message1c: 'Your new one will be: {new_email}',
            changeEmail_message1d:
                'Please click the Change E-Mail button within {minutes} minutes to activate the change and delete the message afterwards. It will work only once.',
            changeEmail_button: 'Change E-Mail',
            changeEmail_message2: 'If you did not change the E-Mail address, no further action is required.',
            changeEmailO_subject: 'E-Mail Change',
            changeEmailO_message1: 'You are receiving this email because someone changed your E-Mail address.',
            changeEmailO_message1b: 'Your previous E-Mail address was: {old_email}',
            changeEmailO_message1c: "It's now: {new_email}",
            changeEmailO_message2:
                'If you did not change the E-Mail address, please contact us at <a href="mailto:account-details-changed@elasticstage.com">account-details-changed@elasticstage.com</a>.',
        },
        pagination: {
            previous: '&laquo; Previous',
            next: 'Next &raquo;',
        },
        passwords: {
            password: 'Passwords must be at least eight characters and match the confirmation.',
            reset: 'Your password has been updated!',
            sent: 'We have e-mailed your password reset link!',
            token: 'This password reset token is invalid.',
            user: "We can't find a user with that e-mail address.",
        },
        validation: {
            accepted: 'The {attribute} must be accepted.',
            active_url: 'The {attribute} is not a valid URL.',
            after: 'The {attribute} must be a date after {date}.',
            after_or_equal: 'The {attribute} must be a date after or equal to {date}.',
            alpha: 'The {attribute} may only contain letters.',
            alpha_dash: 'The {attribute} may only contain letters, numbers, dashes and underscores.',
            alpha_num: 'The {attribute} may only contain letters and numbers.',
            array: 'The {attribute} must be an array.',
            before: 'The {attribute} must be a date before {date}.',
            before_or_equal: 'The {attribute} must be a date before or equal to {date}.',
            between: {
                numeric: 'The {attribute} must be between {min} and {max}.',
                file: 'The {attribute} must be between {min} and {max} kilobytes.',
                string: 'The {attribute} must be between {min} and {max} characters.',
                array: 'The {attribute} must have between {min} and {max} items.',
            },
            boolean: 'The {attribute} field must be true or false.',
            confirmed: 'The {attribute} confirmation does not match.',
            date: 'The {attribute} is not a valid date.',
            date_equals: 'The {attribute} must be a date equal to {date}.',
            date_format: 'The {attribute} does not match the format {format}.',
            different: 'The {attribute} and {other} must be different.',
            digits: 'The {attribute} must be {digits} digits.',
            digits_between: 'The {attribute} must be between {min} and {max} digits.',
            dimensions: 'The {attribute} has invalid image dimensions.',
            distinct: 'The {attribute} field has a duplicate value.',
            email: 'The {attribute} must be a valid email address.',
            exists: 'The selected {attribute} is invalid.',
            file: 'The {attribute} must be a file.',
            filled: 'The {attribute} field must have a value.',
            gt: {
                numeric: 'The {attribute} must be greater than {value}.',
                file: 'The {attribute} must be greater than {value} kilobytes.',
                string: 'The {attribute} must be greater than {value} characters.',
                array: 'The {attribute} must have more than {value} items.',
            },
            gte: {
                numeric: 'The {attribute} must be greater than or equal {value}.',
                file: 'The {attribute} must be greater than or equal {value} kilobytes.',
                string: 'The {attribute} must be greater than or equal {value} characters.',
                array: 'The {attribute} must have {value} items or more.',
            },
            image: 'The {attribute} must be an image.',
            in: 'The selected {attribute} is invalid.',
            in_array: 'The {attribute} field does not exist in {other}.',
            integer: 'The {attribute} must be an integer.',
            ip: 'The {attribute} must be a valid IP address.',
            ipv4: 'The {attribute} must be a valid IPv4 address.',
            ipv6: 'The {attribute} must be a valid IPv6 address.',
            json: 'The {attribute} must be a valid JSON string.',
            lt: {
                numeric: 'The {attribute} must be less than {value}.',
                file: 'The {attribute} must be less than {value} kilobytes.',
                string: 'The {attribute} must be less than {value} characters.',
                array: 'The {attribute} must have less than {value} items.',
            },
            lte: {
                numeric: 'The {attribute} must be less than or equal {value}.',
                file: 'The {attribute} must be less than or equal {value} kilobytes.',
                string: 'The {attribute} must be less than or equal {value} characters.',
                array: 'The {attribute} must not have more than {value} items.',
            },
            max: {
                numeric: 'The {attribute} may not be greater than {max}.',
                file: 'The {attribute} may not be greater than {max} kilobytes.',
                string: 'The {attribute} may not be greater than {max} characters.',
                array: 'The {attribute} may not have more than {max} items.',
            },
            mimes: 'The {attribute} must be a file of type: {values}.',
            mimetypes: 'The {attribute} must be a file of type: {values}.',
            min: {
                numeric: 'The {attribute} must be at least {min}.',
                file: 'The {attribute} must be at least {min} kilobytes.',
                string: 'The {attribute} must be at least {min} characters.',
                array: 'The {attribute} must have at least {min} items.',
            },
            not_in: 'The selected {attribute} is invalid.',
            not_regex: 'The {attribute} format is invalid.',
            numeric: 'The {attribute} must be a number.',
            present: 'The {attribute} field must be present.',
            regex: 'The {attribute} format is invalid.',
            required: 'The {attribute} field is required.',
            required_if: 'The {attribute} field is required when {other} is {value}.',
            required_unless: 'The {attribute} field is required unless {other} is in {values}.',
            required_with: 'The {attribute} field is required when {values} is present.',
            required_with_all: 'The {attribute} field is required when {values} are present.',
            required_without: 'The {attribute} field is required when {values} is not present.',
            required_without_all: 'The {attribute} field is required when none of {values} are present.',
            same: 'The {attribute} and {other} must match.',
            size: {
                numeric: 'The {attribute} must be {size}.',
                file: 'The {attribute} must be {size} kilobytes.',
                string: 'The {attribute} must be {size} characters.',
                array: 'The {attribute} must contain {size} items.',
            },
            starts_with: 'The {attribute} must start with one of the following: {values}',
            string: 'The {attribute} must be a string.',
            timezone: 'The {attribute} must be a valid zone.',
            unique: 'The {attribute} has already been taken.',
            uploaded: 'The {attribute} failed to upload.',
            url: 'The {attribute} format is invalid.',
            uuid: 'The {attribute} must be a valid UUID.',
            custom: {
                'attribute-name': {
                    'rule-name': 'custom-message',
                },
            },
            attributes: {
                a_line1: 'Address Line 1',
                a_line2: 'Address Line 2',
                a_city: 'City',
                a_state: 'State',
                a_postal_code: 'Postal Code',
                a_country: 'Country',
                about: 'About',
                account_type: 'Account Type',
                additional_artists: 'Additional Artists',
                address_line1: 'Address Line 1',
                address_line2: 'Address Line 2',
                address_city: 'Town/Village',
                address_state: 'State',
                address_postal_code: 'Zip/Postal Code',
                address_country: 'Country',
                business_address_line1: 'Address Line 1',
                business_address_line2: 'Address Line 2',
                business_address_city: 'Town/Village',
                business_address_state: 'State',
                business_address_postal_code: 'Zip/Postal Code',
                business_address_country: 'Country',
                business: 'Business',
                business_name: 'Business Name',
                c_year: '© Year',
                c_holder: '© Holder',
                catalog_no: 'Catalog Number',
                content_title: 'Title',
                date: 'Date',
                description: 'Description',
                dob: 'Date of Birth',
                ean: 'EAN-13 Barcode',
                email: 'E-Mail Address',
                eu_vat_number: 'EU VAT-Number',
                event_name: 'Event Name',
                facebook_link: 'Facebook',
                first_name: 'First Name',
                formatted_address: 'Address',
                gender: 'Gender',
                grid: 'GRid',
                instagram_link: 'Instagram',
                label: 'Record Label',
                last_name: 'Last Name',
                maiden_name: 'Maiden Name',
                main_genre: 'Main Genre',
                mobile_phone: 'Mobile Phone',
                name: 'Name',
                p_year: '℗ Year',
                p_holder: '℗ Holder',
                panel_name: 'Panel Name',
                password: 'Password',
                page_id: 'Assigned Page',
                page_name: 'Page Name',
                page_url: 'Page URL',
                paypal_account: 'PayPal Payouts Account',
                personal_id_number: 'ID number',
                preorder_date: 'Pre-order Date',
                primary_artist: 'Primary Artist',
                release_date: 'Release Date',
                release_title: 'Release Title',
                release_type_id: 'Release Type',
                royalty: 'Royalty',
                shipping_phone: 'Phone',
                spotify_link: 'Spotify',
                ssn_last_4: 'SSN',
                sub_genre: 'Sub Genre',
                support_act: 'Supporting Act',
                time: 'Time',
                title: 'Title',
                twitter_link: 'Twitter',
                url: 'URL',
                url_link: 'Web Page',
                venue: 'Venue',
                youtube_link: 'YouTube',
            },
        },
    },
};
