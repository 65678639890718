import { AccountCreatorDetails } from '@/types/account';
import { AccountApiResponse } from '@/types/api/account';
import { AccountState } from '@/types/store/account';

export const accountMapper: (response: AccountApiResponse) => AccountState = (response) => {
    const emptyCreator: AccountCreatorDetails = {
        address: {
            address_line_1: '',
            address_line_2: null,
            city: '',
            postcode: '',
            country_iso: '',
        },
        bank_details: {
            bank_account_holder_name: '',
            bank_account_number: '',
            bank_account_sort_code: '',
        },
        vat_number: null,
        vinyl_capacity: 0,
    };

    const creator: AccountCreatorDetails = response.creator
        ? {
              address: {
                  address_line_1: response.creator.address?.line1 || '',
                  address_line_2: response.creator.address?.line2 || null,
                  city: response.creator.address?.city || '',
                  postcode: response.creator.address?.postcode || '',
                  country_iso: response.creator.address?.country_iso || '',
              },
              bank_details: {
                  bank_account_holder_name: response.creator.bank_details?.details.holderName || '',
                  bank_account_number: response.creator.bank_details?.details.accountNumber.toString() || '',
                  bank_account_sort_code: response.creator.bank_details?.details.sortCode.toString() || '',
              },
              vat_number: response.creator.vat_number || null,
              vinyl_capacity: response.creator.vinyl_capacity,
          }
        : emptyCreator;

    return {
        id: response.id,
        basic: {
            name: response.basic.name || null,
            email: response.basic.email || null,
            business_name: response.basic.business_name || null,
            legal_entity_type: response.basic.legal_entity_type || null,
            phone: response.basic.phone || null,
        },
        creator: creator,
        is_seller: response.is_seller || false,
    };
};
