import SalesService from '@/services/SalesService';

import { SalesActions, SalesGetters, SalesMutations, SalesState } from '@/types/store/sales';

export const state: SalesState = {
    sales: null,
    realtime: null,
    reports_years: [],
    reports_months: [],
};

export const actions: SalesActions = {
    async fetch({ commit, rootState }) {
        const response = await SalesService.fetch({
            userUuid: rootState.user.uuid,
        });

        commit('SET_SALES', response);
    },
    async fetchRealtimeSales({ commit, rootState }) {
        const response = await SalesService.fetchRealTimeSales({
            userUuid: rootState.user.uuid,
        });

        commit('SET_REALTIME_SALES', response);
    },
    async fetchReportsYears({ commit, rootState }) {
        const response = await SalesService.fetchReportsYears({
            userUuid: rootState.user.uuid,
        });

        commit('SET_REPORTS_YEARS', response);
    },
    async fetchReportsMonths({ commit, rootState }, year) {
        const response = await SalesService.fetchReportsMonths({
            userUuid: rootState.user.uuid,
            year,
        });

        commit('SET_REPORTS_MONTHS', response);
    },
    async fetchReport({ rootState }, date) {
        return await SalesService.fetchReport({
            userUuid: rootState.user.uuid,
            date,
        });
    },
};

export const mutations: SalesMutations = {
    SET_SALES(state, sales) {
        state.sales = sales;
    },
    SET_REALTIME_SALES(state, realtimeSales) {
        state.realtime = realtimeSales;
    },
    SET_REPORTS_YEARS(state, years) {
        state.reports_years = years;
    },
    SET_REPORTS_MONTHS(state, reports) {
        state.reports_months = reports;
    },
};

export const getters: SalesGetters = {
    sales: (state: SalesState) => state.sales,
    realtime: (state: SalesState) => state.realtime,
    reportsYears: (state: SalesState) => state.reports_years,
    reportsMonths: (state: SalesState) => state.reports_months,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
