import { AxiosResponse } from 'axios';
import Form from 'form-backend-validation';

import { api } from '@/services/ApiService';
import { pagesMapper } from '@/services/mappers/pages.mapper';

import { PagesApiResponse } from '@/types/api/pages';

export default {
    async list({ userUuid }: { userUuid: string }) {
        const response: AxiosResponse<{ data: PagesApiResponse }> = await api.get(`/api/users/${userUuid}/pages`);

        return pagesMapper(response.data.data);
    },
    async destroy({ pageUuid, userUuid }: { pageUuid: string; userUuid: string }) {
        const response: AxiosResponse<string> = await api.delete(`/api/users/${userUuid}/pages/${pageUuid}`);

        return response.data;
    },
    async store({ form, userUuid }: { form: InstanceType<typeof Form>; userUuid: string }) {
        // This API endpoint does not need to return new data - remove response from server side
        return await form.post(`/api/users/${userUuid}/pages`);
    },
};
