import { AxiosResponse } from 'axios';
import Form from 'form-backend-validation';

import { api } from '@/services/ApiService';
import { pageMapper } from '@/services/mappers/page.mapper';

import { PageApiResponse } from '@/types/api/page';
import { YouTubePlaylistResponse } from '@/types/youtube';

export default {
    async show({ userUuid, pageUuid }: { userUuid: string; pageUuid: string }) {
        const response: AxiosResponse<{ data: PageApiResponse }> = await api.get(
            `/api/users/${userUuid}/pages/${pageUuid}`,
        );

        return pageMapper(response.data.data);
    },
    async update({
        userUuid,
        pageUuid,
        form,
    }: {
        userUuid: string;
        pageUuid: string;
        form: InstanceType<typeof Form>;
    }) {
        const response: AxiosResponse<PageApiResponse> = await form.put(`/api/users/${userUuid}/pages/${pageUuid}`);

        return pageMapper(response.data);
    },
    async getYouTubePlaylists({ url }: { url: string }) {
        const response = await api.post<YouTubePlaylistResponse>(`/api/youtube/playlists`, { url });

        return response.data.data;
    },
    async storePageImage({ userUuid, pageUuid, image }: { userUuid: string; pageUuid: string; image: File }) {
        const formData = new FormData();

        formData.append('image', image);

        const response: AxiosResponse<string> = await api.post(
            `/api/users/${userUuid}/pages/${pageUuid}/page_image`,
            formData,
            {
                headers: { 'Content-Type': 'multipart/form-data' },
            },
        );

        return response.data;
    },
    async storeHeaderImage({ userUuid, pageUuid, image }: { userUuid: string; pageUuid: string; image: File }) {
        const formData = new FormData();

        formData.append('image', image);

        const response: AxiosResponse<string> = await api.post(
            `/api/users/${userUuid}/pages/${pageUuid}/header_image`,
            formData,
            {
                headers: { 'Content-Type': 'multipart/form-data' },
            },
        );

        return response.data;
    },
    async disconnectInstagram({ userUuid, pageUuid }: { userUuid: string; pageUuid: string }) {
        const response: AxiosResponse<string> = await api.delete(
            `/api/users/${userUuid}/pages/${pageUuid}/instagram_auth`,
        );

        return response.data;
    },
};
