import { AxiosResponse } from 'axios';
import Form from 'form-backend-validation';

import { api } from '@/services/ApiService';
import { accountMapper } from '@/services/mappers/account.mapper';

import { AccountApiResponse } from '@/types/api/account';

export default {
    async fetch(uuid: string) {
        const response: AxiosResponse<{ data: AccountApiResponse }> = await api.get(`/api/account/${uuid}`);

        return accountMapper(response.data.data);
    },
    async saveBasicDetails(uuid: string, form: InstanceType<typeof Form>) {
        // FYI - This response is missing the normal data wrapper in json object
        const response: AxiosResponse<AccountApiResponse> = await form.put(`/api/account/${uuid}`);

        return accountMapper(response.data);
    },
    async updatePassword(uuid: string, form: InstanceType<typeof Form>) {
        const response: AxiosResponse<{ data: '' }> = await form.put(`/api/account-password/${uuid}`);

        return response.data.data;
    },
    async saveCreatorDetails(uuid: string, form: typeof Form) {
        const response: [] = await form.post(`/api/account/${uuid}/creator`);

        return response;
    },
    async updateCreatorDetails(uuid: string, form: typeof Form) {
        const response: AxiosResponse<[]> = await form.put(`/api/account/${uuid}/creator`);

        return response.data;
    },
};
