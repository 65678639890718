import { TYPE, useToast } from 'vue-toastification';

export const notify = (message: string, status: keyof typeof TYPE | 'DANGER' = 'SUCCESS', timeout: number = 4000) => {
    const toastStatus: keyof typeof TYPE = status === 'DANGER' ? 'ERROR' : status;

    const toast = useToast();

    return toast(message, {
        timeout,
        type: TYPE[toastStatus],
        hideProgressBar: true,
    });
};

export const notifyPersistent = (message: string, status: keyof typeof TYPE | 'DANGER' = 'SUCCESS') => {
    const toastStatus: keyof typeof TYPE = status === 'DANGER' ? 'ERROR' : status;

    const toast = useToast();

    return toast(message, {
        timeout: false,
        type: TYPE[toastStatus],
        hideProgressBar: true,
    });
};

export const clearNotification = (notificationId: string | number) => {
    const toast = useToast();
    toast.dismiss(notificationId);
};
