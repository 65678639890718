import { NavbarGetters, NavbarMutations, NavbarState } from '@/types/store/navbar';

const state: NavbarState = {
    pageName: null,
    pageUrl: null,
    showPageName: false,
};

const mutations: NavbarMutations = {
    SET_PAGENAME(state: NavbarState, pageName: string) {
        state.pageName = pageName;
    },
    SET_PAGEURL(state: NavbarState, pageUrl: string) {
        state.pageUrl = pageUrl;
    },
    SET_SHOWARTISTNAME(state: NavbarState, showArtistName: boolean) {
        state.showPageName = showArtistName;
    },
};

const getters: NavbarGetters = {
    pageName: (state: NavbarState) => state.pageName,
    pageUrl: (state: NavbarState) => state.pageUrl,
    showPageName: (state: NavbarState) => state.showPageName,
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
};
